import { ReactNode } from "react";

type CardProps = {
	title: string;
	subtitle?: string;
	className?: string;
	icon?: ReactNode;
	headerChildren?: ReactNode;
	children: ReactNode;
};

export default function Card(props: CardProps) {
	let title = "";
	let subtitle = "";

	if (props.title !== undefined) {
		title = props.title;
	}

	if (props.subtitle !== undefined) {
		subtitle = props.subtitle;
	}

	return (
		<>
			<div
				className={`bg-secondary rounded shadow-lg shadow-zinc-800/40 ${props.className}`}
			>
				<div className="flex items-center p-4 border-b border-zinc-800/40 justify-between">
					<div className="flex items-center">
						{props.icon !== undefined ? (
							<div className="flex-none mr-4 ">{props.icon}</div>
						) : (
							""
						)}
						<div>
							<div className="text-xl font-bold">{title}</div>
							{subtitle !== "" ? (
								<p className="text-base mt-1 text-gray-400">{subtitle}</p>
							) : (
								""
							)}
						</div>
					</div>
					<div>{props.headerChildren && props.headerChildren}</div>
				</div>
				<div className="p-4">{props.children}</div>
			</div>
		</>
	);
}
