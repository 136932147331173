import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  onClick?: () => void;
}

export default function DropdownItem(props: Props) {
  return (
    <div
      className="flex items-center border-b border-zinc-700 py-3 
            px-3 w-full hover:bg-zinc-900"
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
