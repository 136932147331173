import { ReactNode, useState } from "react";
import CheveronDown from "./Icons/ChevronDown";
import ChevronUp from "./Icons/ChevronUp";

interface Props {
	children: ReactNode;
	title: string;
	isOpen: boolean;
}

export default function Collapse(props: Props) {
	const [open, setOpen] = useState(props.isOpen);

	return (
		<div className="border border-gray-800/40 rounded">
			<div
				className="py-2 px-4 bg-primary flex justify-between items-center cursor-pointer"
				onClick={() => setOpen(!open)}
			>
				{props.title}
				<div>
					{open && <ChevronUp className="w-5 stroke-orange-500" />}
					{!open && <CheveronDown className="w-5 stroke-orange-500" />}
				</div>
			</div>

			{open && <div className="p-4">{props.children}</div>}
		</div>
	);
}
