import { CalendarIcon } from "@mui/x-date-pickers";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FabricCapacity } from "../helpers/azure-api";
import { CapacitySettings, ManagementConfig } from "../helpers/coe-api";
import useApi from "../hooks/useApi";
import Card from "./Card";
import Dropdown from "./Dropdown";
import DropdownItem from "./DropdownItem";
import { ArrowPath } from "./Icons/ArrowPath";
import ArrowTopRightSquare from "./Icons/ArrowTopRightSquare";
import Beaker from "./Icons/Beaker";
import Cog6 from "./Icons/Cog6";
import { Pause } from "./Icons/Pause";
import { Play } from "./Icons/Play";
import Modal from "./Modal";
import ScheduleModal from "./ScheduleModal";
import { Skeleton } from "./Skeleton";

export default function Fabric() {
	const { azureApi } = useApi();
	const [requesting, setRequesting] = useState(true);
	const [fabricsDetails, setFabricsDetails] = useState<FabricCapacity[]>([]);
	const [modalState, setModalState] = useState(false);
	const [fabricToAction, setFabricToAction] = useState<FabricCapacity>();

	const getMoreInfo = useCallback(
		async (resourceUrl: string, subId: string): Promise<FabricCapacity> => {
			let fc = await azureApi.getResourceDetails(resourceUrl);
			fc.subscriptionId = subId;

			return fc;
		},
		[azureApi]
	);

	const getFabricCapacities = useCallback(async () => {
		const foundFabrics: Record<string, FabricCapacity> = {};
		try {
			setRequesting(true);
			let subResp = await azureApi.getAzureSubscriptions();

			for (const element of subResp.value) {
				const fabs = await azureApi.getFabricResourcesList(
					element.subscriptionId
				);

				for (const v of fabs.value) {
					const fc = await getMoreInfo(v.id, element.subscriptionId);
					foundFabrics[fc.id] = fc;
				}
			}

			const fabricsDetails = Object.values(foundFabrics);
			setFabricsDetails(fabricsDetails);
			setRequesting(false);
		} catch (e) {
			console.log(e);
		}
	}, [azureApi, getMoreInfo]);

	useEffect(() => {
		getFabricCapacities();
	}, [getFabricCapacities]);

	const RESUMING = "resuming";
	const RUNNING = "running";
	const PAUSING = "pausing";
	const PAUSED = "paused";
	const [status, setStatus] = useState("");

	const resumeCapacity = (fc: FabricCapacity | undefined) => {
		if (!fc) {
			return;
		}
		setStatus(RESUMING);
		toast.info("Resuming fabric capacity");

		azureApi.resumeFabricCapacity(fc.id).then(() => {
			setTimeout(() => {
				getFabricCapacities().then(() => {
					setStatus(RUNNING);

					toast.success("Fabric capacity is now running.");
				});
			}, 3000);
		});
	};

	const stopCapacity = (fc: FabricCapacity) => {
		setStatus(PAUSING);
		toast.info("Pausing fabric capacity");

		azureApi.stopFabricCapacity(fc.id).then(() => {
			let handler = setInterval(() => {
				getMoreInfo(fc.id, fc.subscriptionId).then((info) => {
					if (info.properties.state === "Paused") {
						clearInterval(handler);
						let fds = fabricsDetails.filter((f) => f.id !== fc.id);
						fds.push(info);
						setFabricsDetails(fds);
						setStatus(PAUSED);

						toast.success("Fabric capacity paused");
					}
				});
			}, 3000);
		});
	};

	const [skuModal, setSKUModal] = useState("");
	const [capacityToUpdate, setCapacityToUpdate] = useState<FabricCapacity>();
	const [changeCapacityConfirm, setChangeCapacityConfirm] = useState(false);
	const [newCapacitySize, setNewCapacitySize] = useState("");
	const handleCapacitySizeChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		const v = event.target.value;
		setNewCapacitySize(v);
	};

	const changeCapacitySize = () => {
		if (!capacityToUpdate) {
			return;
		}

		azureApi
			.updateResouceSKU(capacityToUpdate.id, newCapacitySize)
			.then((r) => {
				let handler = setInterval(() => {
					getMoreInfo(
						capacityToUpdate.id,
						capacityToUpdate.subscriptionId
					).then((info) => {
						if (info.properties.state === "Paused") {
							clearInterval(handler);
							let fds = fabricsDetails.filter(
								(f) => f.id !== capacityToUpdate.id
							);
							fds.push(info);
							setFabricsDetails(fds);
							setStatus(PAUSED);
						}
					});
				}, 1000);

				setCapacityToUpdate(undefined);
				setSKUModal("");
				setChangeCapacityConfirm(false);
			})
			.catch((e) => {
				console.log("failed to update capacity", e);
			});
	};

	const [showCapacitySettingsModal, setShowCapacitySettingsModal] =
		useState(false);
	// const [capacityForModal] = useState<FabricCapacity>()

	/**const openCapacitySettingsModal = async (fc: FabricCapacity) => {
        const settings: CapacitySettings = await coeAPI.getFabricCapacitySettings(fc.id)

        setCapacityForModal(fc);
        setShowCapacitySettingsModal(true);
        setCapacitySettings(settings);
    }**/

	const offTimeValue = (): string => {
		const d = new Date();
		return d.getHours() + ":" + d.getMinutes();
	};

	const onTimeValue = (): string => {
		const d = new Date();
		return d.getHours() + ":" + d.getMinutes();
	};

	const [capacitySettings, setCapacitySettings] = useState<CapacitySettings>({
		capacity_id: "",
		on_time: onTimeValue(),
		off_time: offTimeValue(),
	});

	const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setCapacitySettings({
			...capacitySettings,
			[name]: value,
		});
	};

	const updateCapacitySettings = () => {
		// if (!capacityForModal) { return }
		// let settings = capacitySettings
		// settings.capacity_id = capacityForModal?.id
		// coeAPI.updateFabricCapacitySettings(settings)
		// setShowCapacitySettingsModal(false)
		// toast.success("Capacity Settings have been updated.")
	};

	const [showScheduleSettings, setShowScheduleSettings] = useState(false);

	return (
		<div className="col-span-12">
			<div>
				<Card
					title="Fabric Capacities"
					icon={<Beaker className="w-6" />}
					headerChildren={
						<div className="flex">
							<button
								className={`btn primary filled large mr-4 flex items-center`}
								onClick={() => getFabricCapacities()}
							>
								<ArrowPath className={`mr-3 w-4`} />
								{requesting ? "Loading..." : "Refresh"}
							</button>
							<button
								className={`btn primary filled large flex items-center`}
								onClick={() => setShowScheduleSettings(true)}
							>
								<CalendarIcon className={`mr-3 w-4`} />
								Schedule
							</button>
						</div>
					}
				>
					<div className="grid grid-cols-12 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full sticky bg-secondary text-sm">
						<div className="col-span-2">Name</div>
						<div className="col-span-2">SKU</div>
						<div className="col-span-2">Location</div>
						<div className="col-span-2">Status</div>
						<div className="col-span-4"></div>
					</div>
					{requesting ? (
						<Skeleton rows={1} />
					) : (
						Array.from(fabricsDetails).map((fc) => (
							<div
								key={fc.id}
								className="text-gray-200 grid grid-cols-12 
                            gap-2 py-2 text-sm border-b border-gray-700
                            hover:bg-zinc-800/40 items-center px-4"
							>
								<div className="col-span-2" id={fc.id}>
									{fc.name}
								</div>
								<div className="col-span-2 flex items-center">
									{fc.sku.name}
									<div
										className="ml-4 p-1 border border-zinc-600 rounded hover:bg-zinc-600 cursor-pointer"
										title="Change Capacity Size"
										onClick={() => {
											setSKUModal(fc.id);
											setCapacityToUpdate(fc);
										}}
									>
										<Cog6 className="w-4" />
									</div>
								</div>
								<div className="col-span-2">{fc.location}</div>
								<div className="col-span-2">
									<div>
										{status === PAUSING && (
											<div className="flex items-center">
												<Pause className="mr-2" /> Pausing...
											</div>
										)}
										{status === RESUMING && (
											<div className="flex items-center">
												<Play className="mr-2" /> Resuming...
											</div>
										)}
										{fc.properties.state === "Paused" &&
											status !== RESUMING && (
												<div className="flex items-center">
													{" "}
													<Pause className="mr-2" /> Paused
												</div>
											)}

										{fc.properties.state === "Active" && status !== PAUSING && (
											<div className="flex items-center">
												{" "}
												<Play className="mr-2" /> Running
											</div>
										)}
									</div>
								</div>
								<div className="col-span-4 flex justify-end">
									<Dropdown className="" dropdownClassName="w-52 text-base">
										{fc.properties.state === "Active" && (
											<DropdownItem onClick={() => stopCapacity(fc)}>
												<button className="text-blue-500 hover:text-blue-600 flex items-center">
													<Pause className="mr-2" />{" "}
													{status === PAUSING ? "Pausing" : "Pause"}
												</button>
											</DropdownItem>
										)}
										{fc.properties.state === "Paused" && (
											<DropdownItem
												onClick={() => {
													setFabricToAction(fc);
													setModalState(true);
												}}
											>
												<button className="text-green-500 hover:text-green-600 flex items-center">
													<Play className="mr-2" />{" "}
													{status === RESUMING ? "Resuming" : "Resume"}
												</button>
											</DropdownItem>
										)}
										{/*<DropdownItem>
                                            <div onClick={() => {openCapacitySettingsModal(v)}} className="flex">
                                                <Cog6 className="w-6 mr-2" /> Settings
                                            </div>
                                        </DropdownItem>(*/}
										<a
											href={`https://portal.azure.com/#resource${fc.id}`}
											rel="noreferrer"
											target="_blank"
											className="flex"
										>
											<DropdownItem>
												<ArrowTopRightSquare className={"w-6 mr-2"} /> Azure
												Portal
											</DropdownItem>
										</a>
									</Dropdown>
								</div>
							</div>
						))
					)}
				</Card>

				<Modal
					open={modalState}
					onClose={() => setModalState(!modalState)}
					actionButton={
						<button
							className="btn bg-green-700 hover:bg-green-800 mr-3 flex items-center"
							onClick={() => {
								resumeCapacity(fabricToAction);
								setModalState(false);
							}}
						>
							<Play className="mr-2" />
							Resume
						</button>
					}
				>
					<div className="w-full flex-col content-center justify-center">
						<h2 className="text-xl mb-6 pb-2 border-b border-gray-600">
							Resume capacity - {fabricToAction?.name}
						</h2>
						<div className="mb-3">
							Are you sure you want to resume the capacity?
						</div>
					</div>
				</Modal>

				<Modal
					open={skuModal !== ""}
					onClose={() => setSKUModal("")}
					actionButton={
						<button
							className="btn bg-green-700 hover:bg-green-800 mr-3 flex items-center py-2"
							onClick={() => {
								changeCapacitySize();
								setSKUModal("");
							}}
							disabled={!changeCapacityConfirm}
						>
							Update Capacity
						</button>
					}
				>
					<div className="w-full flex-col content-center justify-center">
						<h2 className="text-xl mb-6 pb-2 border-b border-gray-600">
							Update the Capacity Size
						</h2>
						<div className="p-4 text-zinc-400 bg-secondary border border-zinc-600 rounded text-sm mb-4">
							<b>Note:</b> Updating the Capacity size will have an increase or
							decrease in your over bill. Please make sure you understand what
							this cost would be before making this change.
							<br />
							<br />
							It will also take a few minutes for the capacity size to change
							and to be reflected in the list.
						</div>
						<label htmlFor="capacity" className="mr-3">
							New Capacity Size:
						</label>
						<select
							name="capacity"
							id="capacity"
							value={newCapacitySize}
							onChange={handleCapacitySizeChange}
							className="text-zinc-300 py-1 px-3 rounded bg-secondary border border-zinc-600 mb-4"
						>
							<option value="f2">F2 (capacity units: 2)</option>
							<option value="f4">F4 (capacity units: 4)</option>
							<option value="f8">F8 (capacity units: 8)</option>
							<option value="f16">F16 (capacity units: 16)</option>
							<option value="f32">F32 (capacity units: 32)</option>
							<option value="f64">F64 (capacity units: 64)</option>
							<option value="f128">F128 (capacity units: 128)</option>
							<option value="f256">F256 (capacity units: 256)</option>
						</select>
						<div className="mb-4">
							<label htmlFor="sure" className="mr-3">
								Are you sure?
							</label>
							<input
								type="checkbox"
								name="sure"
								id="sure"
								checked={changeCapacityConfirm}
								onChange={() =>
									setChangeCapacityConfirm(!changeCapacityConfirm)
								}
							/>
						</div>
					</div>
				</Modal>

				<Modal
					open={showCapacitySettingsModal}
					onClose={() => setShowCapacitySettingsModal(false)}
					actionButton={
						<button
							className="btn bg-green-700 hover:bg-green-800 mr-3 flex items-center py-2"
							onClick={() => {
								updateCapacitySettings();
							}}
						>
							Update Capacity Settings
						</button>
					}
				>
					<div className="w-full flex-col content-center justify-center">
						<h2 className="text-xl mb-6 pb-2 border-b border-gray-600">
							Capacity Settings
						</h2>
						<div className="mb-4">
							<label htmlFor="on_time" className="mr-3">
								On Time:
							</label>
							<input
								name="on_time"
								id="on_time"
								value={capacitySettings.on_time}
								onChange={handleTimeChange}
								type="time"
								required
								className="text-zinc-300 py-1 px-3 rounded bg-secondary border border-zinc-600 mb-4"
							/>
						</div>
						<div className="mb-4">
							<label htmlFor="off_time" className="mr-3">
								Off Time:
							</label>
							<input
								name="off_time"
								id="off_time"
								value={capacitySettings.off_time}
								onChange={handleTimeChange}
								type="time"
								required
								className="text-zinc-300 py-1 px-3 rounded bg-secondary border border-zinc-600 mb-4"
							/>
						</div>
					</div>
				</Modal>

				<ScheduleModal
					open={showScheduleSettings}
					onClose={() => {
						setShowScheduleSettings(false);
					}}
				/>
			</div>
		</div>
	);
}
