import React from "react"

type SmallCardProps = {
    title?: string,
    icon?: React.ReactNode,
    className?: string,
    children: React.ReactNode
}

export default function SmallCard(props: SmallCardProps) {
    let title = ""

    if (props.title !== undefined) {
        title = props.title
    }
    
    return ( 
        <>
            <div className={`bg-secondary rounded p-4 text-center xl:flex 
                justify-between items-center shadow-lg shadow-zinc-800/40 ${props.className}`}>
                <div className="text-base mb-5 xl:mb-0 text-gray-400 font-medium flex justify-center items-center text-center">
                    {props.icon ? <div className="flex-none hidden xl:inline-block xl:mr-4">{props.icon}</div> : "" }
                    <div className="">{title}</div>
                </div>
                <div>
                    {props.children} 
                </div>
            </div> 
        </>
    )
}