import BaseApi from "./base-api";

type EmailAddress = {
  address: string;
};

type Recipient = {
  emailAddress: EmailAddress;
};

type MessageBody = {
  contentType: string;
  content: string;
};

type Message = {
  subject: string;
  body: MessageBody;
  toRecipients: Recipient[];
  ccRecipients?: Recipient[];
};

type SendMail = {
  message: Message;
  saveToSentItems: string; // You might want to consider using a boolean type here
};

class GraphAPI {
  baseAPI: BaseApi;

  constructor(baseApi: BaseApi) {
    this.baseAPI = baseApi;
  }

  async emailUsers(emails: string[], subject: string, content: string) {
    const msg: SendMail = {
      saveToSentItems: "false",
      message: {
        subject,
        body: {
          contentType: "Text",
          content,
        },
        toRecipients: [],
      },
    };

    for (const e of emails) {
      msg.message.toRecipients.push({ emailAddress: { address: e } });

      await this.baseAPI
        .post("https://graph.microsoft.com/v1.0/me/sendMail", msg)
        .then((r) => {
          if (!r) {
            return;
          }

          if (r.status !== 202) {
            console.log(`email wasn't send to $}`);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  async emailUsersAboutWorkspaceIssue(emails: string[], workspaceName: string) {
    const subject = `Alert: ${workspaceName} is currently having issues`;
    const content = `The workspace (${workspaceName}) is currently having some issues.`;

    return this.emailUsers(emails, subject, content);
  }

  async emailUsersAboutSynapseCost(
    emails: string[],
    subscriptionName: string,
    subscriptionId: string,
    budgetStatus: "over" | "within"
  ) {
    const costUrl = `https://portal.azure.com/#view/Microsoft_Azure_CostManagement/CostAnalysis/scope/%2Fsubscriptions%2F${subscriptionId}`;
    const subject = `Alert: ${subscriptionName} is ${budgetStatus} budget`;
    const content = `The subscription ${subscriptionName} is ${budgetStatus} budget. See more at ${costUrl}`;

    return this.emailUsers(emails, subject, content);
  }
}

export default GraphAPI;
