import dayjs from "dayjs";
import config from "../config";
import BaseApi from "./base-api";

const baseURL = config.apiEndpoint;

export type ManagementConfig = {
	service_principal_client_id: string;
	service_principal_client_secret: string;
};

export type CapacitySettings = {
	capacity_id: string;
	on_time: string;
	off_time: string;
};

export type CapacitySettingsResp = {
	config: CapacitySettings;
};

export const DAYS_OF_WEEK = [
	"sunday",
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
] as const;

export type TimeWindow = {
	startTime: dayjs.Dayjs;
	endTime: dayjs.Dayjs;
};

export type Weekday = (typeof DAYS_OF_WEEK)[number];

export type EnabledTimes = Record<Weekday, TimeWindow>;

export type EnabledDays = Record<Weekday, boolean>;

export type FabricSchedule = {
	id: string;
	tenantId: string;
	enabledTimes: EnabledTimes;
	enabledDays: EnabledDays;
};

export type StorageConfig = {
	id: string;
	tenantId: string;
	storageDetails: {
		accountName: string;
		key: string;
		containerName: string;
		path: string;
	};
};

class CoeAPI {
	baseAPI: BaseApi;

	constructor(baseApi: BaseApi) {
		this.baseAPI = baseApi;
	}

	async updateFabricSchedule(
		enabledTimes: EnabledTimes,
		enabledDays: EnabledDays
	) {
		const url = `${baseURL}/FabricSchedule`;

		const res = await this.baseAPI
			.post(url, {
				enabledTimes,
				enabledDays,
			})
			.catch(() => {
				throw new Error("failed to update Fabric schedule");
			});

		if (!res?.ok) {
			throw new Error(`failed to update Fabric schedule: got ${res?.status}`);
		}
	}

	async getFabricSchedule() {
		const url = `${baseURL}/FabricSchedule?tenant_id=${config.tenantId}`;

		const res = await this.baseAPI.get(url).catch(() => {
			throw new Error("failed to get Fabric schedule");
		});

		if (!res?.ok) {
			throw new Error(`failed to get Fabric schedule: got ${res?.status}`);
		}

		const data: FabricSchedule = await res.json();
		return data;
	}

	async updateStorageConfig(storageDetails: StorageConfig["storageDetails"]) {
		const url = `${baseURL}/StorageConfig`;

		const res = await this.baseAPI
			.post(url, {
				storageDetails,
			})
			.catch(() => {
				throw new Error("failed to update storage config");
			});

		if (!res?.ok) {
			throw new Error(`failed to update storage config: got ${res?.status}`);
		}
	}

	async uploadBlob(fileName: string, content: string) {
		const url = `${baseURL}/UploadBlob`;

		const res = await this.baseAPI
			.post(url, {
				fileName,
				content,
			})
			.catch(() => {
				throw new Error("failed to upload blob");
			});

		if (!res?.ok) {
			throw new Error(`failed to upload blob: got ${res?.status}`);
		}
	}

	async updateFabricCapacitySettings(settings: CapacitySettings) {
		const url = `${baseURL}/FabricWorkspaceSettings`;

		this.baseAPI.post(url, settings);
	}

	async getFabricCapacitySettings(id: string) {
		const url = `${baseURL}/FabricWorkspaceSettings?capacity_id=${id}`;

		const resp = await this.baseAPI.get(url);
		if (!resp) {
			throw new Error("request failed");
		}
		if (!resp.ok) {
			throw new Error("failed to get reports as admin");
		}

		const data: CapacitySettingsResp = await resp.json();

		return data.config;
	}

	async proxyRequest(
		url: string,
		method:
			| "GET"
			| "POST"
			| "DELETE"
			| "HEAD"
			| "PATCH"
			| "PUT"
			| "OPTIONS"
			| "TRACE"
			| "CONNECT",
		body?: any,
		headers?: Record<string, string>
	) {
		const proxyServiceUrl = `${baseURL}/ProxyService`;
		return this.baseAPI.post(proxyServiceUrl, {
			method,
			body,
			url,
			headers,
		});
	}
}

export default CoeAPI;
