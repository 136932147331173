import { ReactNode, useEffect, useRef, useState } from "react";
import Bars3 from "./Icons/Bars3";

interface Props {
	children: ReactNode;
	className?: string;
	dropdownClassName?: string;
}

export default function Dropdown(props: Props) {
	const [open, setOpen] = useState(false);
	const ref = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && ref.current != null) {
				if (!ref.current.contains(event.target as Node)) {
					setOpen(false);
				}
			}
		};
		document.addEventListener("click", handleClickOutside);
	}, [ref]);

	return (
		<div ref={ref}>
			<div
				className={`relative cursor-pointer p-1 rounded border border-zinc-600 rounded-lg hover:bg-zinc-600 ${props.className}`}
				onClick={() => setOpen(!open)}
			>
				<Bars3 className="w-5 hover:stroke-orange-500" />

				{open && (
					<div
						className={`right-1 absolute z-50 bg-primary rounded mt-3 shadow-lg ${props.dropdownClassName}`}
					>
						{props.children}
					</div>
				)}
			</div>
		</div>
	);
}
