import { AccountInfo } from "@azure/msal-browser";
import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { msal } from "../App";
import { Workspace } from "../helpers/powerbi-api";
import useApi from "../hooks/useApi";
import Card from "./Card";
import Dropdown from "./Dropdown";
import DropdownItem from "./DropdownItem";
import BellAlert from "./Icons/BellAlert";
import Building from "./Icons/Building";
import ChevronRight from "./Icons/ChevronRight";
import DocumentText from "./Icons/DocumentText";
import FolderArrowDown from "./Icons/FolderArrowDown";
import Modal from "./Modal";
import Cog6 from "./Icons/Cog6";
import { StorageConfig } from "../helpers/coe-api";

interface Checkbox {
	id: string;
	checked: boolean;
}

export default function Workspaces() {
	const accountInfo: AccountInfo | null = msal.getActiveAccount();
	const { powerBiApi, graphApi, coeApi } = useApi();
	let [workspaces, setWorkspaces] = useState<Workspace[]>([]);
	const [modalState, setModalState] = useState(false);
	const [showStorageConfig, setShowStorageConfig] = useState(false);
	const [storageDetails, setStorageDetails] = useState<
		StorageConfig["storageDetails"]
	>({
		accountName: "",
		key: "",
		containerName: "",
		path: "",
	});
	const [alertModalState, setAlertModalState] = useState(false);
	const [workspaceForAlert, setWorkspaceForAlert] = useState<Workspace>();
	const [workspace, setWorkspace] = useState<Workspace>();
	const [selectedForExport, setSelectedForExport] = useState<Checkbox[]>([]);

	useEffect(() => {
		powerBiApi.getGroupDatasets("all").then((data) => {
			if (data) {
				setWorkspaces(data?.value);
			}
		});
	}, [powerBiApi]);

	const openStorageConfigModal = () => {
		setShowStorageConfig(true);
	};
	const closeModal = () => {
		setModalState(false);
	};

	const displayWorkspace = (ws: Workspace) => {
		setWorkspace(ws);
		setModalState(true);
	};

	const handleStorageDetailsChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		setStorageDetails((storageDetails) => ({
			...storageDetails,
			[name]: value,
		}));
	};

	const handleOnCheckboxChange = (id: string) => {
		const checks = selectedForExport;
		let found = false;
		let foundIdx = 0;

		for (let index = 0; index < checks.length; index++) {
			var item = checks[index];
			if (item.id === id) {
				found = true;
				foundIdx = index;
			}
		}

		if (found) {
			checks.splice(foundIdx, 1);
		} else {
			checks.push({ id: id, checked: true });
		}

		setSelectedForExport(checks);
	};

	const downloadExports = () => {
		console.log("trying to download reports");
		if (selectedForExport.length === 0) {
			toast.warning("You haven't selected any reports to download");
			return;
		}

		toast.info("Starting reports download");

		selectedForExport.forEach((v) => {
			let ws = workspaces.find((w) => w.id === v.id);
			if (!ws) {
				return;
			}

			let i = ws.users.filter((u) => u.emailAddress === accountInfo?.username);
			if (i.length > 0) {
				triggerDownload(ws);

				return;
			}
		});
	};

	const grantPermsToAllWorkspaces = () => {
		if (!accountInfo) {
			return;
		}

		workspaces
			.filter((v) => {
				return v.users.some((u) => u.emailAddress !== accountInfo.username);
			})
			.forEach((v) => {
				powerBiApi
					.giveSelfPermissionsToGroup(
						v.id,
						accountInfo.username,
						accountInfo.localAccountId
					)
					.then(() => {})
					.catch((e) => {});
			});

		toast.info(`Granting permissions for all workspaces. 
            This can take up to 2 minutes. 
            Once you have permissions you'll see the checkboxes appear next to the workspace.`);
	};

	const triggerDownload = (ws: Workspace) => {
		ws.reports.forEach((r) => {
			powerBiApi
				.downloadReport(r.id)
				.then((blob) => {
					coeApi.uploadBlob(`${r.name}.pbix`, blob);
				})
				.catch((err) => {
					console.log(err);
					return;
				});
		});
	};
	const triggerEmailAlert = (workspace: Workspace | undefined) => {
		if (!workspace) {
			return;
		}

		let emails: string[] = [];
		workspace.users.forEach((u) => emails.push(u.emailAddress));

		setAlertModalState(false);

		graphApi.emailUsersAboutWorkspaceIssue(emails, workspace.name);

		toast.success("Sending an email to the participants of this workspace.");
	};

	const updateStorageConfig = () => {
		coeApi?.updateStorageConfig(storageDetails);

		setShowStorageConfig(false);

		toast.success("Storage Config have been updated.");
	};

	return (
		<>
			<div className="col-span-12">
				<Card
					title="Workspaces"
					icon={<Building />}
					headerChildren={
						<div className="flex">
							<button
								className={`btn primary filled large mr-4`}
								onClick={grantPermsToAllWorkspaces}
							>
								Grant Permission to Download
							</button>
							<button
								className={`btn primary filled large mr-4 flex items-center`}
								onClick={() => openStorageConfigModal()}
							>
								<Cog6 className={`mr-3 w-4`} />
								Settings
							</button>
							<button
								className={`btn primary filled large flex items-center`}
								onClick={downloadExports}
							>
								<FolderArrowDown className="w-6 mr-2" /> Export PBIX files
							</button>
						</div>
					}
				>
					<div className="grid grid-cols-12 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full bg-secondary sticky text-sm">
						<div className="col-span-4 px-2">Workspace</div>
						<div className="col-span-2 px-2">State</div>
						<div className="col-span-2 px-2"># Reports</div>
						<div className="col-span-2 px-2 hidden lg:block"># Datasets</div>
						<div className="col-span-1 px-2 hidden xl:block"># Users</div>
						<div className="col-span-4 px-2"></div>
					</div>
					{Array.from(workspaces).map((v, key) => (
						<div
							key={key}
							className="text-gray-200 grid grid-cols-12 
                            gap-2 py-2 text-sm border-b border-gray-700
                            hover:bg-zinc-700 items-center"
						>
							<div className="col-span-4 px-2 flex" id={v.id}>
								{v.users.filter((u) => u.emailAddress === accountInfo?.username)
									.length > 0 && (
									<input
										type="checkbox"
										name="export[]"
										id={`export-checkbox-${key}`}
										onChange={() => handleOnCheckboxChange(v.id)}
										className="mr-3 h-4 w-4 bg-primary p-2 border-zinc-500 rounded border accent-orange-500"
									/>
								)}
								{v.name}
							</div>
							<div className="col-span-2 px-2">
								{v.state === "Active" ? (
									<div className="flex items-center text-green-500">
										<div className="bg-green-600 w-2 h-2 rounded-full mr-3"></div>{" "}
										{v.state}
									</div>
								) : (
									v.state
								)}
							</div>
							<div className="col-span-2 px-2">{v.reports.length}</div>
							<div className="col-span-2 px-2 hidden lg:block">
								{v.datasets.length}
							</div>
							<div className="col-span-1 px-2 hidden xl:block">
								{v.users.length}
							</div>
							<div className="col-span-2 xl:col-span-1 flex justify-end px-2">
								<Dropdown className="" dropdownClassName="w-52 text-base">
									<DropdownItem>
										<div
											onClick={(e) => {
												e.preventDefault();
												displayWorkspace(v);
											}}
											className="flex items-center"
										>
											<DocumentText className="w-5 mr-3" />
											Workspace Info
										</div>
									</DropdownItem>
									<DropdownItem>
										<BellAlert className="w-5 mr-3" />
										<div
											onClick={(e) => {
												e.preventDefault();
												setWorkspaceForAlert(v);
												setAlertModalState(true);
											}}
										>
											Alert of problem
										</div>
									</DropdownItem>
								</Dropdown>
							</div>
						</div>
					))}
				</Card>
			</div>
			<Modal open={modalState} onClose={closeModal}>
				{workspace && (
					<div>
						<h2 className="text-xl mb-6 pb-2 border-b border-gray-600">
							Workspace Data for{" "}
							<span className="font-bold">{workspace.name}</span>
						</h2>

						<div className="grid grid-cols-12 gap-6">
							<div className="col-span-6">
								<h3 className="text-lg font-medium mb-2">
									Users ({workspace.users.length})
								</h3>
								{workspace.users.map((v, key) => (
									<div key={key} className="flex items-center">
										<ChevronRight className="w-3 inline-block mr-2 text-orange-500" />{" "}
										{v.displayName}
									</div>
								))}
							</div>
							<div className="col-span-6">
								<h3 className="text-lg font-medium mb-2">
									Datasets ({workspace.datasets.length})
								</h3>
								{workspace.datasets.map((v, key) => (
									<div key={key} className="flex items-center">
										<ChevronRight className="w-3 inline-block mr-2 text-orange-500" />{" "}
										{v.name}
									</div>
								))}
							</div>
							<div className="col-span-6">
								<h3 className="text-lg font-medium mb-2">
									Reports ({workspace.reports.length})
								</h3>
								{workspace.reports.map((v, key) => (
									<div key={key} className="flex items-center" title={v.id}>
										<ChevronRight className="w-3 inline-block mr-2 text-orange-500" />{" "}
										{v.name}
									</div>
								))}
							</div>
						</div>
					</div>
				)}
			</Modal>

			<Modal
				open={alertModalState}
				onClose={() => {
					setAlertModalState(false);
				}}
				actionButton={
					<button
						className="btn primary filled"
						onClick={() => triggerEmailAlert(workspaceForAlert)}
					>
						Send Alert
					</button>
				}
			>
				{workspaceForAlert && (
					<div>
						<h2 className="text-xl mb-6 pb-2 border-b border-gray-600 flex items-center">
							<BellAlert className="w-6 mr-4 stroke-orange-500" />
							Alert workspace users of a problem
						</h2>

						<div className="grid grid-cols-12 gap-6">
							<div className="col-span-6">
								<h3 className="text-lg font-medium mb-2">
									Users this alert will be sent to
								</h3>
								{workspaceForAlert.users.map((v, key) => (
									<div key={key} className="flex items-center">
										<ChevronRight className="w-3 inline-block mr-2 text-orange-500" />{" "}
										{v.displayName}
									</div>
								))}
							</div>
						</div>
					</div>
				)}
			</Modal>

			<Modal
				open={showStorageConfig}
				onClose={() => setShowStorageConfig(false)}
				actionButton={
					<button
						className="btn bg-green-700 hover:bg-green-800 mr-3 flex items-center py-2"
						onClick={() => {
							updateStorageConfig();
						}}
					>
						Update
					</button>
				}
			>
				<div className="w-full flex-col content-center justify-center">
					<h2 className="text-xl mb-6 pb-2 border-b border-gray-600">
						Update PBIX Storage Configuration
					</h2>
					<div className="mb-2">
						<label htmlFor="accountName" className="mr-3 mb-2 block">
							Blob Storage Account Name
						</label>
						<input
							name="accountName"
							id="accountName"
							value={storageDetails.accountName}
							onChange={handleStorageDetailsChange}
							type="text"
							required
							className="text-zinc-300 py-1 px-3 rounded bg-secondary border border-zinc-600 mb-4 w-full"
						/>
					</div>
					<div className="mb-2">
						<label htmlFor="key" className="mr-3 mb-2 block">
							Blob Storage Access Key
						</label>
						<input
							name="key"
							id="key"
							value={storageDetails.key}
							onChange={handleStorageDetailsChange}
							type="text"
							required
							placeholder="***********"
							className="text-zinc-300 py-1 px-3 rounded bg-secondary border border-zinc-600 mb-4 w-full"
						/>
					</div>
					<div className="mb-2">
						<label htmlFor="key" className="mr-3 mb-2 block">
							Container Name
						</label>
						<input
							name="containerName"
							id="containerName"
							value={storageDetails.containerName}
							onChange={handleStorageDetailsChange}
							type="text"
							required
							className="text-zinc-300 py-1 px-3 rounded bg-secondary border border-zinc-600 mb-4 w-full"
						/>
					</div>
					<div className="mb-4">
						<label htmlFor="key" className="mr-3 mb-2 block">
							Base Path
						</label>
						<input
							name="path"
							id="path"
							value={storageDetails.path}
							onChange={handleStorageDetailsChange}
							type="text"
							required
							className="text-zinc-300 py-1 px-3 rounded bg-secondary border border-zinc-600 mb-4 w-full"
						/>
					</div>
				</div>
			</Modal>
		</>
	);
}
