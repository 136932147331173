import CoeAPI from "./coe-api";

class DatabricksAPI {
	coeApi: CoeAPI;
	databricksAccessToken: string;

	constructor(coeApi: CoeAPI, databricksAccessToken: string) {
		this.coeApi = coeApi;
		this.databricksAccessToken = databricksAccessToken;
	}

	async getClusters(workspaceUrl: string) {
		const url = `https://${workspaceUrl}/api/2.0/clusters/list`;

		let response = await this.coeApi.proxyRequest(
			url,
			"GET",
			{},
			{ Authorization: `Bearer ${this.databricksAccessToken}` }
		);
		if (!response) {
			throw new Error("request failed");
		}

		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		const data: ListClustersResponse = await response.json();

		return data;
	}

	async getWarehouses(workspaceUrl: string) {
		const url = `https://${workspaceUrl}/api/2.0/preview/sql/data_sources`;

		let response = await this.coeApi.proxyRequest(
			url,
			"GET",
			{},
			{ Authorization: `Bearer ${this.databricksAccessToken}` }
		);
		if (!response) {
			throw new Error("request failed");
		}

		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		const data: any = await response.json();
		return data;
	}
}

export default DatabricksAPI;

type ListClustersResponse = {
	clusters: Cluster[];
};

export type Cluster = {
	num_workers?: number;
	autoscale?: Autoscale;
	cluster_name: string;
	spark_version: string;
	spark_conf: CustomTags;
	azure_attributes: AzureAttributes;
	node_type_id: string;
	driver_node_type_id: string;
	ssh_public_keys: string[];
	custom_tags: CustomTags;
	cluster_log_conf: ClusterLogConf;
	init_scripts: InitScript[];
	spark_env_vars: CustomTags;
	autotermination_minutes: number;
	enable_elastic_disk: boolean;
	cluster_source: string;
	instance_pool_id: string;
	policy_id: string;
	enable_local_disk_encryption: boolean;
	driver_instance_pool_id: string;
	workload_type: WorkloadType;
	runtime_engine: string;
	docker_image: DockerImage;
	data_security_mode: string;
	single_user_name: string;
	cluster_id: string;
	creator_user_name: string;
	driver: Driver;
	executors: Driver[];
	spark_context_id: number;
	jdbc_port: number;
	state: string;
	state_message: string;
	start_time: number;
	terminated_time: number;
	last_state_loss_time: number;
	last_restarted_time: number;
	cluster_memory_mb: number;
	cluster_cores: number;
	default_tags: CustomTags;
	cluster_log_status: ClusterLogStatus;
	termination_reason: TerminationReason;
	spec: Spec;
};

type Autoscale = {
	min_workers: number;
	max_workers: number;
};

type AzureAttributes = {
	log_analytics_info: LogAnalyticsInfo;
	first_on_demand: string;
	availability: string;
	spot_bid_max_price: string;
};

type LogAnalyticsInfo = {
	log_analytics_workspace_id: string;
	log_analytics_primary_key: string;
};

type ClusterLogConf = {
	dbfs: Dbfs;
};

type Dbfs = {
	destination: string;
};

type ClusterLogStatus = {
	last_attempted: number;
	last_exception: string;
};

type CustomTags = {
	property1: string;
	property2: string;
};

type DockerImage = {
	url: string;
	basic_auth: BasicAuth;
};

type BasicAuth = {
	username: string;
	password: string;
};

type Driver = {
	private_ip: string;
	public_dns: string;
	node_id: string;
	instance_id: string;
	start_timestamp: number;
	host_private_ip: string;
};

type InitScript = {
	workspace: Dbfs;
	volumes: Dbfs;
	file: Dbfs;
	dbfs: Dbfs;
};

type Spec = {
	num_workers: number;
	autoscale: Autoscale;
	cluster_name: string;
	spark_version: string;
	spark_conf: CustomTags;
	azure_attributes: AzureAttributes;
	node_type_id: string;
	driver_node_type_id: string;
	ssh_public_keys: string[];
	custom_tags: CustomTags;
	cluster_log_conf: ClusterLogConf;
	init_scripts: InitScript[];
	spark_env_vars: CustomTags;
	autotermination_minutes: number;
	enable_elastic_disk: boolean;
	cluster_source: string;
	instance_pool_id: string;
	policy_id: string;
	enable_local_disk_encryption: boolean;
	driver_instance_pool_id: string;
	workload_type: WorkloadType;
	runtime_engine: string;
	docker_image: DockerImage;
	data_security_mode: string;
	single_user_name: string;
	apply_policy_default_values: string;
};

type WorkloadType = {
	clients: Clients;
};

type Clients = {
	notebooks: string;
	jobs: string;
};

type TerminationReason = {
	code: string;
	type: string;
	parameters: CustomTags;
};

export type DatabricksWorkspacesResponse = {
	value: DatabricksWorkspace[];
};

export type DatabricksWorkspace = {
	properties: Properties;
	id: string;
	name: string;
	type: string;
	sku: Sku;
	location: string;
	tags: {};
};

type Properties = {
	managedResourceGroupId: string;
	parameters: Parameters;
	provisioningState: string;
	authorizations: Authorization[];
	createdBy: AtedBy;
	updatedBy: AtedBy;
	workspaceId: string;
	workspaceUrl: string;
	createdDateTime: Date;
};

type Authorization = {
	principalId: string;
	roleDefinitionId: string;
};

type AtedBy = {
	oid: string;
	puid: string;
	applicationId: string;
};

type Parameters = {
	enableFedRampCertification: EnableFedRampCertification;
	enableNoPublicIp: EnableFedRampCertification;
	prepareEncryption: EnableFedRampCertification;
	publicIpName: PublicIPName;
	requireInfrastructureEncryption: EnableFedRampCertification;
	resourceTags: ResourceTags;
	storageAccountName: PublicIPName;
	storageAccountSkuName: PublicIPName;
	vnetAddressPrefix: PublicIPName;
};

type EnableFedRampCertification = {
	type: string;
	value: boolean;
};

type PublicIPName = {
	type: string;
	value: string;
};

type ResourceTags = {
	type: string;
	value: ResourceTagsValue;
};

type ResourceTagsValue = {
	application: string;
	"databricks-environment": string;
};

type Sku = {
	name: string;
};
