import { ReactNode, createContext, useEffect, useMemo, useState } from "react";
import { msal } from "../App";
import Loading from "../components/Loading";
import {
	azureScopes,
	coeScopes,
	dataPlaneScopes,
	databricksScopes,
	generalScopes,
	powerBiScopes,
} from "../helpers/auth";
import AzureAPI from "../helpers/azure-api";
import BaseApi from "../helpers/base-api";
import CoeAPI from "../helpers/coe-api";
import DatabricksAPI from "../helpers/databricks-api";
import GraphAPI from "../helpers/graph-api";
import {
	default as PowerBIAPI,
	default as PowerBiApi,
} from "../helpers/powerbi-api";
import SynapseDataPlaneAPI from "../helpers/synapse-dataplane-api";

type ApiContextType = {
	powerBiApi: PowerBIAPI;
	azureApi: AzureAPI;
	graphApi: GraphAPI;
	dataPlaneApi: SynapseDataPlaneAPI;
	coeApi: CoeAPI;
	databricksApi: DatabricksAPI;
	loading: boolean;
};

export const ApiContext = createContext<ApiContextType | undefined>(undefined);

export default function ApiProvider({ children }: { children: ReactNode }) {
	const account = msal.getActiveAccount();
	const [loading, setLoading] = useState(true);
	const [powerBiApi, setPowerBiApi] = useState<PowerBiApi>();
	const [azureApi, setAzureApi] = useState<AzureAPI>();
	const [graphApi, setGraphApi] = useState<GraphAPI>();
	const [dataPlaneApi, setDataPlaneApi] = useState<SynapseDataPlaneAPI>();
	const [coeApi, setCoeApi] = useState<CoeAPI>();
	const [databricksApi, setDatabricksApi] = useState<DatabricksAPI>();

	useEffect(() => {
		async function fetchTokens() {
			if (!account) {
				return;
			}

			try {
				const response = await msal.acquireTokenSilent(azureScopes);

				if (!response.fromCache || !azureApi) {
					const base = new BaseApi(response.accessToken, msal);
					const azureApi = new AzureAPI(base);
					setAzureApi(azureApi);
				}
			} catch (err) {
				console.error("Error getting Azure token:", err);
				await msal.loginRedirect(azureScopes);
			}

			try {
				const response = await msal.acquireTokenSilent(generalScopes);

				if (!response.fromCache || !graphApi) {
					const base = new BaseApi(response.accessToken, msal);
					const graphInst = new GraphAPI(base);
					setGraphApi(graphInst);
				}

				setLoading(false);
			} catch (err) {
				console.error("Error getting graph token:", err);
			}

			try {
				const response = await msal.acquireTokenSilent(powerBiScopes);

				if (!response.fromCache || !powerBiApi) {
					const base = new BaseApi(response.accessToken, msal);
					const inst = new PowerBiApi(base);
					setPowerBiApi(inst);
				}

				setLoading(false);
			} catch (err) {
				console.error("Error getting powerbi token:", err);
			}

			try {
				const response = await msal.acquireTokenSilent(dataPlaneScopes);

				if (!response.fromCache || !dataPlaneApi) {
					const base = new BaseApi(response.accessToken, msal);
					const dataPlaneApi = new SynapseDataPlaneAPI(base);
					setDataPlaneApi(dataPlaneApi);
				}

				setLoading(false);
			} catch (err) {
				console.error("Error getting Synapse DataPlane API token:", err);
			}

			try {
				const response = await msal.acquireTokenSilent(databricksScopes);

				if ((!response.fromCache || !databricksApi) && coeApi) {
					const databricksApi = new DatabricksAPI(coeApi, response.accessToken);
					setDatabricksApi(databricksApi);
				}

				setLoading(false);
			} catch (err) {
				console.error("Error getting Databricks API token:", err);
			}

			try {
				const response = await msal.acquireTokenSilent(coeScopes);

				if (!response.fromCache || !coeApi) {
					const base = new BaseApi(response.accessToken, msal);
					const coeApi = new CoeAPI(base);
					setCoeApi(coeApi);
				}

				setLoading(false);
			} catch (err) {
				console.error("Error getting COE API token:", err);
			}
		}

		fetchTokens();
	}, [
		account,
		azureApi,
		coeApi,
		dataPlaneApi,
		graphApi,
		powerBiApi,
		databricksApi,
	]);

	const contextValue = useMemo(
		() => ({
			powerBiApi,
			azureApi,
			dataPlaneApi,
			graphApi,
			coeApi,
			databricksApi,
			loading,
		}),
		[
			azureApi,
			coeApi,
			dataPlaneApi,
			graphApi,
			loading,
			powerBiApi,
			databricksApi,
		]
	);

	const isApiComplete =
		contextValue.powerBiApi &&
		contextValue.azureApi &&
		contextValue.graphApi &&
		contextValue.dataPlaneApi &&
		contextValue.databricksApi;

	return loading || !isApiComplete ? (
		<div className="p-24 min-h-full">
			<Loading />
		</div>
	) : (
		<ApiContext.Provider value={contextValue as NonNullable<ApiContextType>}>
			{children}
		</ApiContext.Provider>
	);
}
