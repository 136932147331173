export const costRequestBody = {
  type: "ActualCost",
  dataSet: {
    granularity: "None",
    aggregation: {
      totalCost: {
        name: "Cost",
        function: "Sum",
      },
    },
    sorting: [
      {
        direction: "ascending",
        name: "UsageDate",
      },
    ],
    filter: {
      Dimensions: {
        Name: "ServiceName",
        Operator: "In",
        Values: ["Azure Synapse Analytics"],
      },
    },
  },
  timeframe: "BillingMonthToDate",
};
