import { useContext } from "react";
import { ApiContext } from "../contexts/ApiProvider";

export default function useApi() {
	const apiContext = useContext(ApiContext);

	if (apiContext === undefined) {
		throw new Error("Using API out of ApiProvider context");
	}

	return apiContext;
}
