import { PublicClientApplication } from "@azure/msal-browser";
import { generalScopes } from "./auth";

class BaseApi {
	token: string;
	msalInstance: PublicClientApplication;

	retries: number;

	constructor(token: string, msalInstance: PublicClientApplication) {
		this.token = token;
		this.msalInstance = msalInstance;
		this.retries = 0;
	}

	public async get(url: RequestInfo) {
		this.retries = 0;
		return this.fetcher(url, "GET", {});
	}

	public async post(url: RequestInfo, body: Object): Promise<Response | null> {
		this.retries = 0;
		return this.fetcher(url, "POST", body);
	}

	public async patch(url: RequestInfo, body: Object): Promise<Response | null> {
		this.retries = 0;
		return this.fetcher(url, "PATCH", body);
	}

	private async fetcher(
		url: RequestInfo,
		method: string,
		body: Object
	): Promise<Response | null> {
		if (this.retries === 3) {
			throw new Error("max retries of 3 reached.");
		}

		let info = {};
		if (method === "POST" || method === "PATCH") {
			info = {
				method: method,
				headers: {
					Authorization: "Bearer " + this.token,
					"content-type": "application/json",
				},
				body: JSON.stringify(body),
			};
		}

		if (method === "GET") {
			info = {
				method: method,
				headers: {
					Authorization: "Bearer " + this.token,
					"content-type": "application/json",
				},
			};
		}

		const resp = await fetch(url, info);

		if (!resp.ok) {
			if (resp.status === 401) {
				console.log(
					"got a 401, requesting a new access token, retry count:",
					this.retries
				);

				try {
					this.relogin();
				} catch (e) {
					console.log(e);
					return null;
				}

				this.retries++;
				return this.fetcher(url, method, body);
			}
		}

		return resp;
	}

	private async relogin() {
		try {
			const resp = await this.msalInstance.acquireTokenSilent(generalScopes);
			this.token = resp.accessToken;
		} catch (err) {
			console.log(err);
			throw new Error("failed to get access token");
		}
	}
}

export default BaseApi;
