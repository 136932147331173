import BaseApi from "./base-api";
import { DatabricksWorkspacesResponse } from "./databricks-api";
import { costRequestBody } from "./payloads";
import { BigDataPools, SQLPools, WorkspaceResponse } from "./synapse";

export interface SubscriptionPolicies {
	locationPlacementId: string;
	quotaId: string;
	spendingLimit: string;
}

export interface ManagedByTenant {
	tenantId: string;
}

export interface Tags {
	[key: string]: string;
}

export interface Subscription {
	id: string;
	subscriptionId: string;
	tenantId: string;
	displayName: string;
	state: string;
	subscriptionPolicies: SubscriptionPolicies;
	authorizationSource: string;
	managedByTenants: ManagedByTenant[];
	tags: Tags;
}

export interface SubscriptionsResponse {
	value: Subscription[];
}

export interface FabricCapacity {
	subscriptionId: string;
	properties: {
		provisioningState: string;
		state: string;
		administration: {
			members: string[];
		};
	};
	id: string;
	name: string;
	type: string;
	location: string;
	sku: {
		name: string;
		tier: string;
	};
	tags: {
		[key: string]: string;
	};
}

export interface AzureResourceListItem {
	id: string;
	name: string;
	type: string;
	sku: AzureResourceSKU;
	kind: string;
	location: string;
}

export interface AzureResourceSKU {
	name: string;
	tier: string;
}

export interface AzureResourceList {
	value: AzureResourceListItem[];
}

class AzureAPI {
	baseAPI: BaseApi;

	constructor(baseApi: BaseApi) {
		this.baseAPI = baseApi;
	}

	async getFabricResourcesList(
		subscriptionId: string
	): Promise<AzureResourceList> {
		const url = `https://management.azure.com/subscriptions/${subscriptionId}/resources?$expand=createdTime,provisioningState,state&api-version=2023-07-01-preview`;
		const response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		const data: AzureResourceList = await response.json();
		data.value = data.value.filter(
			(i) => i.type === "Microsoft.Fabric/capacities"
		);

		return data;
	}

	async getAzureSubscriptions(): Promise<SubscriptionsResponse> {
		const url =
			"https://management.azure.com/subscriptions?api-version=2022-12-01";
		const response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		const data: SubscriptionsResponse = await response.json();

		return data;
	}

	async getResourceDetails(resourceUrl: string): Promise<FabricCapacity> {
		const url = `https://management.azure.com${resourceUrl}?api-version=2022-07-01-preview`;
		const response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		const data: FabricCapacity = await response.json();

		return data;
	}

	async resumeFabricCapacity(capacityId: string): Promise<null> {
		const url = `https://management.azure.com${capacityId}/resume?api-version=2022-07-01-preview`;

		await this.baseAPI.post(url, {});

		return null;
	}

	async stopFabricCapacity(capacityId: string): Promise<null> {
		const url = `https://management.azure.com${capacityId}/suspend?api-version=2022-07-01-preview`;

		await this.baseAPI.post(url, {});

		return null;
	}

	async updateResouceSKU(
		resourceId: string,
		newSKUName: string
	): Promise<Response | null> {
		const url = `https://management.azure.com${resourceId}?api-version=2022-07-01-preview`;

		const body = {
			sku: {
				name: newSKUName,
				tier: "Fabric",
			},
		};

		const response = this.baseAPI.patch(url, body);
		if (!response) {
			throw new Error("request failed");
		}

		return response;
	}

	async getSynapseWorkspaces(subId: string) {
		const url = `https://management.azure.com${subId}/providers/Microsoft.Synapse/workspaces?api-version=2021-06-01`;

		const response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		const data: WorkspaceResponse = await response.json();

		return data;
	}

	async getDatabricksWorkspaces(subId: string) {
		const url = `https://management.azure.com${subId}/providers/Microsoft.Databricks/workspaces?api-version=2023-02-01`;

		const response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		const data: DatabricksWorkspacesResponse = await response.json();

		return data;
	}

	async getSynapseCosts(subId: string) {
		const url = `https://management.azure.com${subId}/providers/Microsoft.CostManagement/query?api-version=2023-03-01`;

		const response = await this.baseAPI.post(url, costRequestBody);

		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		const costs = await response.json();
		return costs;
	}

	async getSynapseSQLPoolsForWorkspace(wid: string) {
		const url = `https://management.azure.com${wid}/sqlPools?api-version=2021-06-01`;

		const response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		const data: SQLPools = await response.json();

		return data;
	}

	async getSynapseBigDataPoolsForWorkspace(wid: string) {
		const url = `https://management.azure.com${wid}/bigDataPools?api-version=2021-06-01-preview`;

		const response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		const data: BigDataPools = await response.json();

		return data;
	}
}

export default AzureAPI;
