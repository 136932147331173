import { useEffect, useRef, useState } from "react";
import CheveronDown from "./Icons/ChevronDown";
import ChevronUp from "./Icons/ChevronUp";

export interface Option {
	label: string;
	value: string;
}

interface Props {
	defaultValue: Option | null;
	options: Option[];
	onSelect: Function;
}

export default function CustomSelect(props: Props) {
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState<Option | null>(null);
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && ref.current != null) {
				if (!ref.current.contains(event.target as Node)) {
					setOpen(false);
				}
			}
		};
		document.addEventListener("click", handleClickOutside);
	}, [ref]);

	const showSelected = (): Option => {
		if (selected) {
			return selected;
		}

		if (props.defaultValue !== null) {
			return props.defaultValue;
		}

		return { label: "Filter", value: "" };
	};

	const handleOptionClick = (option: Option) => {
		setOpen(false);
		setSelected(option);
		props.onSelect(option.value);
	};

	return (
		<div ref={ref}>
			<div
				className="px-4 py-2 rounded border border-zinc-700 bg-primary w-full relative flex items-center justify-between cursor-pointer"
				onClick={() => setOpen(!open)}
			>
				{showSelected().label}
				{open ? (
					<ChevronUp className="w-5 stroke-orange-500" />
				) : (
					<CheveronDown className="w-5 stroke-orange-500" />
				)}

				{open && (
					<div
						className="h-[200px] overflow-y-scroll
                    absolute z-50 bg-primary rounded mt-12 shadow right-0 top-0 w-full"
					>
						<div
							key="all"
							className="px-4 py-2 w-full hover:bg-zinc-900 cursor-pointer"
							onClick={() => handleOptionClick({ label: "All", value: "all" })}
						>
							All
						</div>
						{props.options.map((option) => (
							<div
								key={option.value}
								className="px-4 py-2 hover:bg-zinc-900 cursor-pointer hover:border-r-4 border-orange-500"
								onClick={() => handleOptionClick(option)}
							>
								{option.label}
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
}
