import BaseApi from "./base-api";
import { Pipelines } from "./synapse";

class SynapseDataPlaneAPI {
	baseAPI: BaseApi;

	constructor(baseApi: BaseApi) {
		this.baseAPI = baseApi;
	}

	async getSynapsePipelines(workspaceName: string) {
		const url = `https://${workspaceName}.dev.azuresynapse.net/pipelines?api-version=2020-12-01`;

		var response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}

		if (!response.ok) {
			throw new Error(response.status.toString());
		}

		const data: Pipelines = await response.json();

		return data;
	}
}

export default SynapseDataPlaneAPI;
