import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useMsal,
} from "@azure/msal-react";
import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { msal } from "../App";
import ApiProvider from "../contexts/ApiProvider";
import { generalScopes } from "../helpers/auth";

const LoginButton = () => {
	const loginHandler = () => {
		msal.loginRedirect(generalScopes);
	};

	return (
		<div className="py-12 text-center">
			<div className="mb-6 text-zinc-100">
				Clicking login will log you in to your Microsoft account. This is
				required to use this app.
			</div>
			<button onClick={loginHandler} className="btn primary filled">
				Sign in with Microsoft
			</button>
		</div>
	);
};

const LogoutButton = () => {
	const logoutHandler = () => {
		msal.logoutRedirect();
	};

	return (
		<div
			className={`p-3 hover:bg-zinc-600 cursor-pointer`}
			onClick={logoutHandler}
		>
			Logout
		</div>
	);
};

export default function Tab() {
	const { accounts } = useMsal();
	const account = accounts[0];

	const [invalidLicence, setInvalidLicence] = useState(false);

	useEffect(() => {
		async function getAzureToken() {
			// validate against the licencing server.
			// Replace with the URL of your locally hosted function
			try {
				const functionUrl =
					"https://coe-licencing-server.azurewebsites.net/api/CheckTenantId";

				const queryParams = new URLSearchParams({
					tenant_id: account.tenantId,
				});
				const urlWithQueryParams = `${functionUrl}?${queryParams}`;

				const resp = await fetch(urlWithQueryParams);
				if (resp.status !== 200) {
					setInvalidLicence(true);
					toast.error(
						"You are using an unlicenced tenant id. Please contact Power 365 Solutions."
					);
					return;
				}
			} catch (err) {
				console.log(err);
				toast.error(
					"You are using an unlicenced tenant id. Please contact Power 365 Solutions."
				);
				return;
			}
		}

		if (account) {
			getAzureToken();
		}
	}, [account]);

	return (
		<>
			<AuthenticatedTemplate>
				<div className="bg-primary text-neutral-300 min-h-full">
					<ApiProvider>
						<ToastContainer
							position="top-center"
							autoClose={5000}
							theme="dark"
							hideProgressBar
							draggable={false}
							pauseOnFocusLoss={false}
							pauseOnHover={false}
						/>
						{invalidLicence && (
							<div className="p-24 min-h-full text-center text-3xl">
								You are using an invalid tenant login.
								<br />
								Please contact Power 365 Solutions to get this fixed.
							</div>
						)}
						{!invalidLicence && (
							<div className="mx-auto grid grid-cols-12 gap-8 p-8">
								<div className="col-span-12 bg-secondary rounded shadow-lg flex">
									<NavLink to="/">
										<div className={`p-3 hover:bg-zinc-600 cursor-pointer`}>
											Overview
										</div>
									</NavLink>
									<NavLink to="/workspaces">
										<div className={`p-3 hover:bg-zinc-600 cursor-pointer`}>
											Workspaces
										</div>
									</NavLink>
									<NavLink to="/fabric">
										<div className={`p-3 hover:bg-zinc-600 cursor-pointer`}>
											Fabric
										</div>
									</NavLink>
									<NavLink to="/synapse">
										<div className={`p-3 hover:bg-zinc-600 cursor-pointer`}>
											Synapse
										</div>
									</NavLink>
									<NavLink to="/databricks">
										<div className={`p-3 hover:bg-zinc-600 cursor-pointer`}>
											Databricks
										</div>
									</NavLink>
									<LogoutButton />
								</div>
								<div id="tabs" className="grid grid-cols-12 col-span-12 gap-6">
									<Outlet />
								</div>
							</div>
						)}
					</ApiProvider>
				</div>
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				<LoginButton />
			</UnauthenticatedTemplate>
		</>
	);
}
