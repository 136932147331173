import { LoadingButton } from "@mui/lab";
import {
	CircularProgress,
	Skeleton as MUISkeleton,
	Slider,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { msal } from "../App";
import { Subscription } from "../helpers/azure-api";
import GraphAPI from "../helpers/graph-api";
import { BigDataPool, Pipeline, SQLPool, Workspace } from "../helpers/synapse";
import useApi from "../hooks/useApi";
import Card from "./Card";
import Beaker from "./Icons/Beaker";
import BellAlert from "./Icons/BellAlert";
import CircleStack from "./Icons/CircleStack";
import InboxStack from "./Icons/InboxStack";
import { Skeleton } from "./Skeleton";

const MIN_BUDGET = 0;
const MAX_BUDGET = 10000;
const DEFAULT_BUDGET = 1000;

const SubscriptionRow = ({
	subscription,
	cost,
	graphApi,
}: {
	subscription: Subscription;
	cost?: number | null;
	graphApi: GraphAPI;
}) => {
	const [budget, setBudget] = useState(DEFAULT_BUDGET);
	const [isEmailing, setIsEmailing] = useState(false);
	const hasCost = typeof cost === "number";
	const withinBudget = hasCost && cost < budget;
	const costColor = withinBudget ? "bg-green-800" : "bg-red-800";
	const accountInfo = msal.getActiveAccount();

	const handleAlert = () => {
		setIsEmailing(true);

		if (!accountInfo?.username) {
			toast.error("No email was found for account");
			return;
		}

		const accountEmail = accountInfo.username;
		const emails = [accountEmail];
		toast.info(`Emailing ${accountEmail}...`);
		graphApi
			.emailUsersAboutSynapseCost(
				emails,
				subscription.displayName,
				subscription.subscriptionId,
				withinBudget ? "within" : "over"
			)
			.then(() => {
				setIsEmailing(false);
			});
	};

	return (
		<div
			className="text-gray-200 grid grid-cols-12 
              gap-2 py-2 text-sm border-b border-gray-700
              hover:bg-zinc-700 items-center"
		>
			<div className="col-span-4" id={subscription.id}>
				{subscription.id}
			</div>
			<div className="col-span-2">{subscription.displayName}</div>
			<div className="col-span-1 flex">
				{cost === undefined ? (
					<MUISkeleton variant="rounded" height={30} width={80} />
				) : (
					cost && (
						<div
							className={`text-md ${costColor} font-bold rounded-full px-2 py-1 text-center`}
						>
							{cost.toFixed(2)} GBP
						</div>
					)
				)}
			</div>
			<div className="col-span-2">
				<Slider
					size="small"
					value={budget}
					onChange={(_e, budget) => {
						setBudget(Array.isArray(budget) ? budget[0] : budget);
					}}
					valueLabelDisplay="auto"
					min={MIN_BUDGET}
					max={MAX_BUDGET}
				/>
			</div>
			<div className="col-span-2 flex justify-end">
				{cost === undefined ? (
					<MUISkeleton variant="rounded" height={30} width={30} />
				) : (
					<LoadingButton
						startIcon={!isEmailing && <BellAlert className="w-6" />}
						color="secondary"
						onClick={handleAlert}
						className="p-0 justify-end"
						loading={isEmailing}
						loadingPosition="end"
						loadingIndicator={<CircularProgress color="secondary" size={22} />}
						disabled={!hasCost}
					/>
				)}
			</div>
		</div>
	);
};

const WorkspaceRow = ({ workspace }: { workspace: Workspace }) => {
	return (
		<div
			className="text-gray-200 grid grid-cols-12 
              gap-2 py-2 text-sm border-b border-gray-700
              hover:bg-zinc-700 items-center"
		>
			<div className="col-span-2" id={workspace.id}>
				{workspace.name}
			</div>
			<div className="col-span-2">
				{workspace.properties.managedResourceGroupName}
			</div>
			<div className="col-span-2">{workspace.location}</div>
			<div className="col-span-2 flex">
				<div className="text-xs bg-yellow-800 border-yellow-300 font-bold rounded-full px-2 py-1">
					Public Network: {workspace.properties.publicNetworkAccess}
				</div>
			</div>
			<div className="col-span-4 flex justify-end"></div>
		</div>
	);
};

const SQLPoolRow = ({ pool }: { pool: SQLPool }) => {
	return (
		<div
			className="text-gray-200 grid grid-cols-12 
              gap-2 py-2 text-sm border-b border-gray-700
              hover:bg-zinc-700 items-center"
		>
			<div className="col-span-2" id={pool.id}>
				{pool.name}
			</div>
			<div className="col-span-2">{pool.sku.name}</div>
			<div className="col-span-2">{pool.location}</div>
			<div className="col-span-2">{pool.properties.status}</div>
			<div className="col-span-4 justify-end"></div>
		</div>
	);
};

const BigDataPoolRow = ({ pool }: { pool: BigDataPool }) => {
	return (
		<div
			className="text-gray-200 grid grid-cols-12 
              gap-2 py-2 text-sm border-b border-gray-700
              hover:bg-zinc-700 items-center"
		>
			<div className="col-span-2" id={pool.id}>
				{pool.name}
			</div>
			<div className="col-span-2">{pool.location}</div>
			<div className="col-span-2">
				{pool.properties.autoScale.enabled ? "Enabled" : "Disabled"}
			</div>
			<div className="col-span-2 flex">
				{pool.properties.autoPause.enabled ? "Enabled" : "Disabled"}
			</div>
			<div className="col-span-2 flex">{pool.properties.nodeSize}</div>
			<div className="col-span-2">{pool.properties.nodeCount}</div>
		</div>
	);
};

const PipelineRow = ({ pipeline }: { pipeline: Pipeline }) => {
	return (
		<div
			className="text-gray-200 grid grid-cols-12 
          gap-2 py-2 text-sm border-b border-gray-700
          hover:bg-zinc-700 items-center"
		>
			<div className="col-span-2" id={pipeline.id}>
				{pipeline.name}
			</div>
			<div className="col-span-2">{pipeline.properties.description}</div>
			<div className="col-span-4 flex justify-end"></div>
		</div>
	);
};

export default function Synapse() {
	const { azureApi, dataPlaneApi, graphApi } = useApi();

	const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
	const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);
	const [costs, setCosts] = useState<Record<string, number>>({});
	const [loadingCosts, setLoadingCosts] = useState(true);
	const [loadingWorkspaces, setLoadingWorkspaces] = useState(true);
	const [loadingPools, setLoadingPools] = useState(true);
	const [loadingPipelines, setLoadingPipelines] = useState(true);
	const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
	const [sqlPools, setSQLPools] = useState<SQLPool[]>([]);
	const [bigDataPools, setBigDatapools] = useState<BigDataPool[]>([]);
	const [pipelines, setPipelines] = useState<Pipeline[]>([]);

	const getSubscriptions = useCallback(async () => {
		const subs = await azureApi.getAzureSubscriptions();
		return subs.value;
	}, [azureApi]);

	const getWorkspaces = useCallback(
		async (subscriptions: Subscription[]) => {
			console.log("getting workspace data");
			const foundWorkspaces: Record<string, Workspace> = {};

			for (const sub of subscriptions) {
				console.log("getting workspaces for sub", sub.id);

				const subWorkspaces = (await azureApi.getSynapseWorkspaces(sub.id))
					.value;
				for (const ws of subWorkspaces) {
					foundWorkspaces[ws.id] = ws;
				}
			}

			const workspaces = Object.values(foundWorkspaces);
			console.log("done getting workspaces");
			return workspaces;
		},
		[azureApi]
	);

	const getCosts = useCallback(
		async (subscriptions: Subscription[]) => {
			const costsBySub: Record<string, number> = {};
			for (const sub of subscriptions) {
				try {
					const costs = await azureApi.getSynapseCosts(sub.id);
					const gbpCost = costs?.properties?.rows?.[0]?.[0];
					if (gbpCost) {
						costsBySub[sub.id] = gbpCost;
					}
				} catch (err) {
					console.error(
						"error getting costs for subscription",
						sub.displayName
					);
				}
			}
			setCosts(costsBySub);
			setLoadingCosts(false);
		},
		[azureApi]
	);

	const getSqlAndBigDataPools = useCallback(
		async (workspaces: Workspace[]) => {
			console.log("getting sql pools & big data", workspaces);
			const foundSqlPools: Record<string, SQLPool> = {};
			const foundBigDataPools: Record<string, BigDataPool> = {};
			for (const w of workspaces) {
				const sp = await azureApi.getSynapseSQLPoolsForWorkspace(w.id);
				sp.value.forEach((sp) => (foundSqlPools[sp.id] = sp));

				const bdp = await azureApi.getSynapseBigDataPoolsForWorkspace(w.id);
				bdp.value.forEach((bdp) => (foundBigDataPools[bdp.id] = bdp));
			}

			const sqlPools = Object.values(foundSqlPools);
			const bigDataPools = Object.values(foundBigDataPools);
			console.log("done getting pools");
			return { sqlPools, bigDataPools };
		},
		[azureApi]
	);

	const getPipelinesForWorkspaces = useCallback(
		async (workspaces: Workspace[]) => {
			if (!dataPlaneApi) {
				return [];
			}
			console.log("getting pipelines");
			const foundPipelines: Record<string, Pipeline> = {};
			for (const workspace of workspaces) {
				const workspaceName = workspace.name;
				try {
					const ps = await dataPlaneApi.getSynapsePipelines(workspaceName);
					for (const pipeline of ps.value) {
						foundPipelines[pipeline.id] = pipeline;
					}
				} catch (e) {
					console.log(`failed to get pipelines for ${workspaceName}`, e);
				}
			}

			const pipelines = Object.values(foundPipelines);
			console.log("done getting pipelines");
			return pipelines;
		},
		[dataPlaneApi]
	);

	const fetchData = useCallback(async () => {
		const subscriptions = await getSubscriptions();
		setSubscriptions(subscriptions);
		setLoadingSubscriptions(false);
		getCosts(subscriptions);

		const workspaces = await getWorkspaces(subscriptions);
		setWorkspaces(workspaces);
		setLoadingWorkspaces(false);

		getSqlAndBigDataPools(workspaces).then(({ sqlPools, bigDataPools }) => {
			setSQLPools(sqlPools);
			setBigDatapools(bigDataPools);
			setLoadingPools(false);
		});

		getPipelinesForWorkspaces(workspaces).then((pipelines) => {
			setPipelines(pipelines);
			setLoadingPipelines(false);
		});
	}, [
		getCosts,
		getPipelinesForWorkspaces,
		getSqlAndBigDataPools,
		getSubscriptions,
		getWorkspaces,
	]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<div className="col-span-12">
			<div>
				<Card
					title="Synapse Costs"
					icon={<Beaker className="w-6" />}
					className="mb-8"
				>
					<div className="grid grid-cols-12 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full sticky bg-secondary text-sm">
						<div className="col-span-4">ID</div>
						<div className="col-span-2">Name</div>
						<div className="col-span-1">Current Bill</div>
						<div className="col-span-2">Budget</div>
						<div className="col-span-2 flex justify-end">Alert</div>
					</div>
					{loadingSubscriptions ? (
						<Skeleton rows={3} />
					) : (
						Array.from(subscriptions).map((sub) => (
							<SubscriptionRow
								subscription={sub}
								cost={loadingCosts ? undefined : costs?.[sub.id] ?? null}
								key={sub.id}
								graphApi={graphApi}
							/>
						))
					)}
				</Card>
				<Card
					title="Synapse Workspaces"
					icon={<Beaker className="w-6" />}
					className="mb-8"
				>
					<div className="grid grid-cols-12 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full sticky bg-secondary text-sm">
						<div className="col-span-2">Name</div>
						<div className="col-span-2">Resource Group Name</div>
						<div className="col-span-2">Location</div>
						<div className="col-span-2">Network Access</div>
						<div className="col-span-2">
							Current Cost (Billing Cycle to Date)
						</div>
						<div className="col-span-2">Cost Alert</div>
					</div>

					{loadingWorkspaces ? (
						<Skeleton rows={2} />
					) : (
						Array.from(workspaces).map((ws) => (
							<WorkspaceRow workspace={ws} key={ws.id} />
						))
					)}
				</Card>
				<Card
					title="Synapse Pipelines"
					icon={<Beaker className="w-6" />}
					className="mb-8"
				>
					<div className="grid grid-cols-12 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full sticky bg-secondary text-sm">
						<div className="col-span-2">Name</div>
						<div className="col-span-2">Description</div>
					</div>

					{loadingPipelines ? (
						<Skeleton rows={2} />
					) : (
						Array.from(pipelines).map((ps) => (
							<PipelineRow pipeline={ps} key={ps.id} />
						))
					)}
				</Card>
				<Card title="SQL Pools" icon={<CircleStack />} className="mb-8">
					{loadingPools ? (
						<Skeleton rows={1} />
					) : sqlPools.length === 0 ? (
						<div>No SQL Pools found</div>
					) : (
						<div>
							<div className="grid grid-cols-12 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full sticky bg-secondary text-sm">
								<div className="col-span-2">Name</div>
								<div className="col-span-2">SKU</div>
								<div className="col-span-2">Location</div>
								<div className="col-span-2">Status</div>
								<div className="col-span-4"></div>
							</div>

							{sqlPools.map((pool) => (
								<SQLPoolRow pool={pool} key={pool.id} />
							))}
						</div>
					)}
				</Card>
				<Card title="Big Data Pools" icon={<InboxStack />} className="mb-8">
					{loadingPools ? (
						<Skeleton rows={2} />
					) : bigDataPools.length === 0 ? (
						<div>No Big Data Pools found</div>
					) : (
						<div>
							<div className="grid grid-cols-12 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full sticky bg-secondary text-sm">
								<div className="col-span-2">Name</div>
								<div className="col-span-2">Location</div>
								<div className="col-span-2">Auto Scaling</div>
								<div className="col-span-2">Auto Pausing</div>
								<div className="col-span-2">Node Size</div>
								<div className="col-span-2">Node Count</div>
							</div>

							{bigDataPools.map((pool) => (
								<BigDataPoolRow pool={pool} key={pool.id} />
							))}
						</div>
					)}
				</Card>
			</div>
		</div>
	);
}
