import CustomSelect from "./CustomSelect"

interface FilterProps {
    onChange: Function
}

export default function DateFilter(props: FilterProps) {
    // value here is months in days
    const opts: { label: string, value: string }[] = [
        { label: "12 Months", value: "365" },
        { label: "6 Months", value: "180" },
        { label: "3 Months", value: "90" },
        { label: "1 Month", value: "30" },
    ]

    return (
        <CustomSelect
            defaultValue={{ label: "3 Months", value: "91" }}
            options={opts}
            onSelect={(value: number) => props.onChange(value)}
        />
    )
}