import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import {
	LocalizationProvider,
	TimePicker,
	TimeValidationError,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
	DAYS_OF_WEEK,
	type EnabledDays,
	type EnabledTimes,
	type Weekday,
} from "../helpers/coe-api";
import useApi from "../hooks/useApi";

dayjs.extend(utc);

type ScheduleModalProps = {
	open: boolean;
	onClose: () => void;
};

const ScheduleModal: React.FC<ScheduleModalProps> = ({ open, onClose }) => {
	const { coeApi } = useApi();
	const initialEnabledDays: EnabledDays = {} as EnabledDays;
	const initialEnabledTimes: EnabledTimes = {} as EnabledTimes;
	DAYS_OF_WEEK.forEach((day) => {
		initialEnabledTimes[day] = {
			startTime: dayjs(0),
			endTime: dayjs(0).add(1, "day").subtract(1, "minute"),
		};
		initialEnabledDays[day] = true;
	});
	const [enabledTimes, setEnabledTimes] =
		useState<EnabledTimes>(initialEnabledTimes);
	const [enabledDays, setEnabledDays] =
		useState<EnabledDays>(initialEnabledDays);

	const [error, setError] = useState<TimeValidationError | null>(null);
	const errorMessage = React.useMemo(() => {
		switch (error) {
			case "minTime": {
				return "End time must be greater than start";
			}

			case "invalidDate": {
				return "Your time is not valid";
			}

			default: {
				return "";
			}
		}
	}, [error]);

	useEffect(() => {
		coeApi
			.getFabricSchedule()
			.then((schedule) => {
				const dayJsEnabledTimes = _.mapValues(
					schedule.enabledTimes,
					(times) => ({
						startTime: dayjs(times.startTime),
						endTime: dayjs(times.endTime),
					})
				);
				setEnabledTimes(dayJsEnabledTimes);
				setEnabledDays(schedule.enabledDays);
			})
			.catch(() => {
				console.log("unable to find schedule for tenant");
			});
	}, [coeApi]);

	const handleSave = () => {
		coeApi.updateFabricSchedule(enabledTimes, enabledDays);
		onClose();
	};

	const copyToAll = (day: Weekday) => {
		const { startTime, endTime } = enabledTimes[day];
		const enabledDay = enabledDays[day];

		const updatedEnabledTimes = { ...enabledTimes };
		const updatedEnabledDays = { ...enabledDays };
		DAYS_OF_WEEK.forEach((otherDay) => {
			if (otherDay !== day) {
				updatedEnabledTimes[otherDay] = { startTime, endTime };
				updatedEnabledDays[otherDay] = enabledDay;
			}
		});
		setEnabledTimes(updatedEnabledTimes);
		setEnabledDays(updatedEnabledDays);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Dialog open={open} onClose={onClose} maxWidth="lg">
				<DialogTitle>Edit Schedule (UTC)</DialogTitle>
				<DialogContent>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Day</TableCell>
									<TableCell>Start</TableCell>
									<TableCell>End</TableCell>
									<TableCell>Enabled</TableCell>
									<TableCell>Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{DAYS_OF_WEEK.map((day) => (
									<TableRow key={day}>
										<TableCell>
											<Typography
												variant="subtitle2"
												style={{ textTransform: "capitalize" }}
											>
												{day}
											</Typography>
										</TableCell>
										<TableCell>
											<TimePicker
												ampm={false}
												value={enabledTimes[day].startTime}
												timezone="UTC"
												onChange={(newValue) =>
													setEnabledTimes((prevEnabledTimes) => ({
														...prevEnabledTimes,
														[day]: {
															...prevEnabledTimes[day],
															startTime: newValue,
														},
													}))
												}
												maxTime={enabledTimes[day].endTime.subtract(
													1,
													"minute"
												)}
												onError={(newError) => setError(newError)}
												slotProps={{
													textField: {
														helperText: errorMessage,
													},
												}}
												disabled={!enabledDays[day]}
												disableIgnoringDatePartForTimeValidation
											/>
										</TableCell>
										<TableCell>
											<TimePicker
												disableIgnoringDatePartForTimeValidation
												timezone="UTC"
												ampm={false}
												value={enabledTimes[day].endTime}
												onChange={(newValue) =>
													setEnabledTimes((prevEnabledTimes) => ({
														...prevEnabledTimes,
														[day]: {
															...prevEnabledTimes[day],
															endTime: newValue,
														},
													}))
												}
												minTime={enabledTimes[day].startTime.add(1, "minute")}
												onError={(newError) => setError(newError)}
												slotProps={{
													textField: {
														helperText: errorMessage,
													},
												}}
												disabled={!enabledDays[day]}
											/>
										</TableCell>
										<TableCell>
											<Switch
												checked={enabledDays[day]}
												onChange={() =>
													setEnabledDays((prevEnabledDays) => ({
														...prevEnabledDays,
														[day]: !prevEnabledDays[day],
													}))
												}
											/>
										</TableCell>
										<TableCell>
											<Button
												variant="outlined"
												color="primary"
												onClick={() => copyToAll(day)}
											>
												Copy to all
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSave} color="primary" disabled={!!error}>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</LocalizationProvider>
	);
};

export default ScheduleModal;
