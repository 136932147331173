import { useCallback, useEffect, useState } from "react";
import PowerBIAPI, { DataSource, Dataset } from "../../helpers/powerbi-api";
import Card from "../Card";
import Collapse from "../Collapse";
import CircleStack from "../Icons/CircleStack";
import Loading from "../Loading";

interface Props {
	datasets: [string: string, Dataset: Dataset][];
	api: PowerBIAPI;
}

export default function Datasources(props: Props) {
	const [datasources, setDatasources] = useState<DataSource[]>([]);
	const [sqlDatasources, setSqlDatasources] = useState<DataSource[]>([]);
	const [odataDatasources, setOdataDatasources] = useState<DataSource[]>([]);
	const [extensionsDatasources, setExtensionDatasources] = useState<
		DataSource[]
	>([]);

	const [loading, setLoading] = useState(false);

	const getDatasources = useCallback(async () => {
		setLoading(true);
		let dss: DataSource[] = [];
		let eds: DataSource[] = [];
		let sds: DataSource[] = [];
		let dds: DataSource[] = [];

		for (let idx = 0; idx < props.datasets.length; idx++) {
			const dataset = props.datasets[idx];

			let ds = await props.api.getDatasources(dataset[1].id);
			if (ds.value.length === 0) {
				continue;
			}

			dss = [...dss, ...ds.value];
		}

		setDatasources(dss);

		dss.forEach((v) => {
			switch (v.datasourceType) {
				case "Extension":
					eds = [...eds, v];
					break;
				case "SQL":
					sds = [...sds, v];
					break;
				case "OData":
					dds = [...dds, v];
					break;
			}
		});

		setExtensionDatasources(eds);
		setSqlDatasources(sds);
		setOdataDatasources(dds);

		setLoading(false);
	}, [props.api, props.datasets]);

	useEffect(() => {
		if (datasources.length === 0 && !loading) {
			getDatasources();
		}
	}, [datasources.length, getDatasources, loading]);

	return (
		<Card
			title="Data Sources"
			subtitle="Check in to see what data sources you are absorbing."
			icon={<CircleStack />}
		>
			<div className="h-[300px] overflow-y-scroll ">
				{loading && <Loading />}
				{!loading && (
					<div>
						{sqlDatasources.length > 0 && (
							<Collapse title={`Sql (${sqlDatasources.length})`} isOpen={true}>
								<div className="grid grid-cols-4 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full sticky bg-secondary text-sm">
									<div className="col-span-2">Database</div>
									<div className="col-span-2">Server</div>
								</div>

								{sqlDatasources.map((value, k) => (
									<div
										key={k}
										className="text-gray-200 grid grid-cols-4 
                            gap-2 py-2 text-sm border-b border-zinc-800/40
                            hover:bg-zinc-700 items-center"
									>
										<div className="col-span-2">
											{value.connectionDetails.database}
										</div>
										<div className="col-span-2">
											{value.connectionDetails.server}
										</div>
									</div>
								))}
							</Collapse>
						)}

						<Collapse
							title={`Extensions (${extensionsDatasources.length})`}
							isOpen={false}
						>
							<div className="grid grid-cols-4 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full sticky bg-secondary text-sm">
								<div className="col-span-2">Path</div>
								<div className="col-span-2">Kind</div>
							</div>

							{extensionsDatasources.map((value, k) => (
								<div
									key={k}
									className="text-gray-200 grid grid-cols-4 
                            gap-2 py-2 text-sm border-b border-zinc-800/40
                            hover:bg-zinc-700 items-center"
								>
									<div className="col-span-2">
										{value.connectionDetails.path}
									</div>
									<div className="col-span-2">
										{value.connectionDetails.kind}
									</div>
								</div>
							))}
						</Collapse>

						<Collapse
							title={`OData (${odataDatasources.length})`}
							isOpen={false}
						>
							<div className="grid grid-cols-4 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full sticky bg-secondary text-sm">
								<div className="col-span-4">URL</div>
							</div>

							{odataDatasources.map((value, k) => (
								<div
									key={k}
									className="text-gray-200 grid grid-cols-4 
                            gap-2 py-2 text-sm border-b border-zinc-800/40
                            hover:bg-zinc-700 items-center"
								>
									<div className="col-span-4">
										{value.connectionDetails.url}
									</div>
								</div>
							))}
						</Collapse>
					</div>
				)}
			</div>
		</Card>
	);
}
