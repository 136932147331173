import { PopupRequest, SilentRequest } from "@azure/msal-browser";
import config from "../config";

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const generalScopes: SilentRequest = {
	scopes: [
		"https://graph.microsoft.com/User.Read",
		"https://graph.microsoft.com/Mail.Send",
	],
};

export const powerBiScopes: SilentRequest = {
	scopes: [
		"https://analysis.windows.net/powerbi/api/Tenant.Read.All",
		"https://analysis.windows.net/powerbi/api/Report.ReadWrite.All",
	],
};

export const azureScopes: PopupRequest = {
	scopes: ["https://management.azure.com/user_impersonation"],
};

export const dataPlaneScopes: SilentRequest = {
	scopes: ["https://dev.azuresynapse.net/.default"],
};

export const databricksScopes: SilentRequest = {
	scopes: ["2ff814a6-3304-4ab8-85cb-cd0e6f879c1d/.default"],
};

export const coeScopes: SilentRequest = {
	scopes: [`api://${config.apiFunctionSp}/access_as_user`],
};
