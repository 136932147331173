import { useCallback, useEffect, useState } from "react";
import { Dataset, Group, PowerBIReports, User } from "../helpers/powerbi-api";
import useApi from "../hooks/useApi";
import Card from "./Card";
import CustomSelect from "./CustomSelect";
import DatasetBreakdown from "./Home/DatasetBreakdown";
import DatasetsOverTime from "./Home/DatasetsOvertime";
import DatasetsPerReportOverTime from "./Home/DatasetsPerReportsOverTime";
import Datasources from "./Home/Datasources";
import Top5Creators from "./Home/Top5Creators";
import TrendingReports from "./Home/TrendingReports";
import Building from "./Icons/Building";
import DocumentText from "./Icons/DocumentText";
import Tables from "./Icons/Tables";
import Users from "./Icons/Users";
import SmallCard from "./SmallCard";

export default function Overview() {
	const [filterWorkspace, setFilterWorkspace] = useState("");
	const [workspaces, setWorkspaces] = useState(new Map<string, string>());
	const [reportData, setReportData] = useState<PowerBIReports | null>(null);
	const [groupData, setGroupData] = useState<Group | null>(null);
	const [datasetsData, setDatasetsData] = useState<Map<string, Dataset>>(
		new Map<string, Dataset>()
	);
	const [datasetCounts, setDatasetCounts] = useState<Map<string, number>>(
		new Map<string, number>()
	);
	const [reportsPerUser, setReportsPerUser] = useState<Map<string, number>>(
		new Map<string, number>()
	);
	const [users, setUsers] = useState<Map<string, User>>(
		new Map<string, User>()
	);
	const { powerBiApi } = useApi();

	const getReports = useCallback(() => {
		powerBiApi
			.getReports(filterWorkspace)
			.then((reports) => {
				setReportData(reports);
				setReportsPerUser(powerBiApi.getReportsPerUser());
			})
			.catch((e) => {
				console.log(e);
			});
	}, [powerBiApi, filterWorkspace]);

	const getGroupData = useCallback(() => {
		powerBiApi
			.getGroupDatasets(filterWorkspace)
			.then((data) => {
				setGroupData(data);
				const datasets = powerBiApi.getDatasets();
				setDatasetsData(datasets);
				setDatasetCounts(powerBiApi.getDatasetCounts());
				setWorkspaces(powerBiApi.getWorkspaces());
				setUsers(powerBiApi.getUsers());
			})
			.catch((e) => {
				console.log(e);
			});
	}, [filterWorkspace, powerBiApi]);

	useEffect(() => {
		getReports();
		getGroupData();
	}, [getReports, getGroupData, filterWorkspace]);

	useEffect(() => {
		getReports();
		getGroupData();
	}, [getReports, getGroupData]);

	const workspaceSelectOptions = () => {
		let options: { label: string; value: string }[] = [];

		workspaces.forEach((i, v) => {
			options.push({ label: i, value: v });
		});

		return options;
	};

	return (
		<>
			<div className="col-span-12 grid grid-cols-12 gap-6">
				<Card title="Filter Workspace" className="col-span-4 mb-0">
					<CustomSelect
						defaultValue={null}
						options={workspaceSelectOptions()}
						onSelect={(value: string) => setFilterWorkspace(value)}
					/>
				</Card>
				<SmallCard
					title="Workspaces"
					icon={<Building />}
					className="bg-gradient-to-l from-amber-500/5 col-span-2"
				>
					<div className="text-5xl text-amber-500">
						{groupData?.value.length}
					</div>
				</SmallCard>
				<SmallCard
					title="Reports"
					icon={<DocumentText className="w-6 mr-3" />}
					className="bg-gradient-to-l from-blue-500/5 col-span-2"
				>
					<div className="text-5xl text-blue-500">
						{reportData?.value.length}
					</div>
				</SmallCard>
				<SmallCard
					title="Users"
					icon={<Users />}
					className="bg-gradient-to-l from-green-500/5 col-span-2"
				>
					<div className="text-5xl text-green-500">{users.size}</div>
				</SmallCard>
				<SmallCard
					title="Datasets"
					icon={<Tables />}
					className="bg-gradient-to-l from-pink-500/5 col-span-2"
				>
					<div className="text-5xl text-pink-500">{datasetsData.size}</div>
				</SmallCard>
			</div>
			<div className="col-span-12 grid grid-cols-12 gap-6">
				{datasetCounts ? (
					<div className="col-span-12 2xl:col-span-6">
						<DatasetBreakdown
							apiManager={powerBiApi}
							datasetCounts={datasetCounts}
							datasets={datasetsData}
							users={users}
						/>
					</div>
				) : (
					"loading"
				)}
				{reportsPerUser && reportData ? (
					<div className="col-span-12 2xl:col-span-6">
						<Top5Creators
							reportsPerUser={reportsPerUser}
							users={users}
							reports={reportData}
						/>
					</div>
				) : (
					"loading"
				)}
				<div className="col-span-12 2xl:col-span-6">
					<Datasources datasets={Array.from(datasetsData)} api={powerBiApi} />
				</div>

				<div className="col-span-6">
					{reportData && <TrendingReports reports={reportData} />}
				</div>
				<div className="col-span-6">
					<DatasetsOverTime datasets={datasetsData} />
				</div>
				<div className="col-span-6">
					{reportData && (
						<DatasetsPerReportOverTime
							reports={reportData}
							datasets={datasetsData}
						/>
					)}
				</div>
			</div>
		</>
	);
}
