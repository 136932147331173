import {
	AuthenticationResult,
	EventMessage,
	EventType,
	PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { TeamsContext } from "./components/Context";
import Databricks from "./components/Databricks";
import Fabric from "./components/Fabric";
import Overview from "./components/Overview";
import Synapse from "./components/Synapse";
import Workspaces from "./components/Workspaces";
import config from "./config";
import theme from "./helpers/theme";
import Tab from "./pages/Tab";

export const msal = new PublicClientApplication({
	auth: {
		clientId: config.clientId,
		authority: config.authority,
		redirectUri: "/",
		postLogoutRedirectUri: "/",
	},
	system: {
		allowNativeBroker: false, // Disables WAM Broker
		allowRedirectInIframe: true,
	},
});

// Default to using the first account if no account is active on page load
if (!msal.getActiveAccount() && msal.getAllAccounts().length > 0) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msal.setActiveAccount(msal.getAllAccounts()[0]);
}

msal.addEventCallback((event: EventMessage) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		const payload = event.payload as AuthenticationResult;
		const account = payload.account;
		msal.setActiveAccount(account);
	}
});

const router = createBrowserRouter([
	{
		element: <Tab />,
		children: [
			{
				path: "/",
				element: <Overview />,
			},
			{
				path: "/workspaces",
				element: <Workspaces />,
			},
			{
				path: "/fabric",
				element: <Fabric />,
			},
			{
				path: "/synapse",
				element: <Synapse />,
			},
			{
				path: "/databricks",
				element: <Databricks />,
			},
		],
	},
]);

export default function App() {
	const teamsContext = useTeamsUserCredential({
		initiateLoginEndpoint: "/",
		clientId: config.clientId!,
	});
	const { loading } = teamsContext;

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<TeamsContext.Provider value={teamsContext}>
					<MsalProvider instance={msal}>
						{!loading && <RouterProvider router={router} />}
					</MsalProvider>
				</TeamsContext.Provider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
