import { ReactNode } from "react";

interface Props {
    open: boolean;
    children: ReactNode
    onClose: Function
    actionButton?: ReactNode
    title?: string
}

export default function Modal(props: Props) {
    return (
        <>
            {!props.open ? ("") : (
                <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="fixed inset-0 bg-neutral-900 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full mt-12 justify-center p-4 text-center sm:p-0">
                            <div className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 w-[700px]">
                                <div className="bg-primary px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    {props.title && (
                                        <h2 className="text-xl mb-6 pb-2 border-b border-gray-600">{props.title}</h2>
                                    )}

                                    <div className="mb-4">
                                        {props.children}
                                    </div>

                                    <div className="flex justify-between">
                                        {props.actionButton}
                                        <button
                                            className="btn primary font-bold"
                                            onClick={() => props.onClose()}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
