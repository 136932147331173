export function hydrateMapForLastNMonths(months: number) {
    const map = new Map();
    const currentDate = new Date();

    for (let i = 0; i < months; i++) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Month is 0-indexed
        const key = `${year}-${month.toString().padStart(2, "0")}`;

        map.set(key, 0); // Initialize count to 0
        currentDate.setMonth(currentDate.getMonth() - 1); // Move to the previous month
    }

    return map;
}

export function sortDateKeyedArray(arr: { name: string, count: number }[]): { name: string, count: number }[] {
    arr = arr.slice().sort((a, b) => {
        const dateA = new Date(a.name);
        const dateB = new Date(b.name);

        return dateA.getTime() - dateB.getTime()
    })

    return arr
}