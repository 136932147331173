import { useEffect, useState } from "react";
import {
	Area,
	AreaChart,
	CartesianGrid,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from "recharts";
import {
	hydrateMapForLastNMonths,
	sortDateKeyedArray,
} from "../../helpers/dates";
import { PowerBIReports } from "../../helpers/powerbi-api";
import Card from "../Card";
import DateFilter from "../DateFilter";
import ChartBar from "../Icons/ChartBar";

interface Props {
	reports: PowerBIReports;
}

export default function TrendingReports(props: Props) {
	const [data, setData] = useState<{ name: string; count: number }[]>([]);
	// default is 91 days (3 months)
	const [filterTime, setFilterTime] = useState(90);

	useEffect(() => {
		const buildGraphData = () => {
			const currentDate = new Date();
			currentDate.setDate(currentDate.getDate() - filterTime);

			const reportsPerDay = hydrateMapForLastNMonths(filterTime / 30);

			props.reports.value.forEach((r) => {
				const date = new Date(r.createdDateTime);

				if (date < currentDate) {
					return;
				}

				const year = date.getFullYear().toString(); // "2021"
				const month = (date.getMonth() + 1).toString().padStart(2, "0"); // "10" (January is 0-based)
				const key = `${year}-${month}`;

				var count = reportsPerDay.get(key);
				if (count) {
					count++;
				} else {
					count = 1;
				}

				reportsPerDay.set(key, count);
			});

			let arrayData: { name: string; count: number }[] = Array.from(
				reportsPerDay,
				([name, count]) => ({ name, count })
			);

			arrayData = sortDateKeyedArray(arrayData);

			setData(arrayData);
		};
		buildGraphData();
	}, [props.reports, filterTime]);

	return (
		<Card
			title="Trending Reports"
			subtitle="At what velocity are reports being created?"
			icon={<ChartBar />}
			headerChildren={
				<div className="w-40">
					<DateFilter onChange={(v: number) => setFilterTime(v)} />
				</div>
			}
		>
			<div className="h-[300px] text-sm">
				<ResponsiveContainer width="100%" height="100%">
					<AreaChart
						data={data}
						margin={{
							top: 10,
							right: 10,
							left: -5,
							bottom: 10,
						}}
					>
						<defs>
							<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor="#de430a" stopOpacity={0.5} />
								<stop offset="80%" stopColor="#de430a" stopOpacity={0} />
							</linearGradient>
						</defs>
						<CartesianGrid
							strokeDasharray="0 0"
							vertical={false}
							stroke="#32333e"
						/>
						<YAxis
							width={30}
							stroke="#a1a1aa"
							axisLine={false}
							tickLine={false}
							className="text-zinc-400"
						/>
						<XAxis dataKey="name" stroke="#a1a1aa" />
						<Area
							type="monotone"
							dataKey="count"
							stroke="#de430a"
							strokeWidth={2}
							fillOpacity={1}
							fill="url(#colorUv)"
						/>
					</AreaChart>
				</ResponsiveContainer>
			</div>
		</Card>
	);
}
