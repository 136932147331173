import BaseApi from "./base-api";
import { hydrateMapForLastNMonths } from "./dates";
import { blobToBase64 } from "./files";

export interface PowerBIReports {
	value: PowerBIReport[];
}

export interface PowerBIReport {
	id: string;
	reportType: string;
	name: string;
	webUrl: string;
	embedUrl: string;
	datasetId: string;
	createdDateTime: string;
	modifiedDateTime: string;
	modifiedBy: string;
	createdBy: string;
	users: any[]; // Change the type accordingly based on the actual data structure
	subscriptions: any[]; // Change the type accordingly based on the actual data structure
	workspaceId: string;
}

export interface Dataset {
	id: string;
	name: string;
	addRowsAPIEnabled: boolean;
	configuredBy: string;
	isRefreshable: boolean;
	isEffectiveIdentityRequired: boolean;
	isEffectiveIdentityRolesRequired: boolean;
	isOnPremGatewayRequired: boolean;
	targetStorageMode: string;
	createdDate: string;
	ContentProviderType: string;
	isInPlaceSharingEnabled: boolean;
}

export interface GroupReport {
	id: string;
	reportType: string;
	name: string;
	datasetId: string;
	createdDateTime: string;
	modifiedDateTime: string;
}

export interface User {
	emailAddress: string;
	groupUserAccessRight: string;
	displayName: string;
	identifier: string;
	principalType: string;
}

export interface Workspace {
	id: string;
	isReadOnly: boolean;
	isOnDedicatedCapacity: boolean;
	capacityMigrationStatus: string;
	description: string;
	type: string;
	state: string;
	name: string;
	hasWorkspaceLevelSettings: boolean;
	datasets: Dataset[];
	reports: GroupReport[];
	users: User[];
}

export interface Group {
	value: Workspace[];
}

export interface DataSource {
	name: string;
	connectionString: string;
	datasourceType: string;
	datasourceId: string;
	gatewayId: string;
	connectionDetails: {
		server: string;
		database: string;
		path: string;
		kind: string;
		url: string;
	};
}

export interface DataSources {
	value: DataSource[];
}

class PowerBIAPI {
	baseAPI: BaseApi;
	reports: PowerBIReports | null;
	group: Group | null;

	datasets: Map<string, Dataset>;

	// datasource key is the dataset id.
	datasources: Map<string, DataSources>;
	users: Map<string, User>;
	workspaces: Map<string, string>;
	reportsPerDataset: Map<string, number>;
	reportsPerUser: Map<string, number>;
	reportsPerDay: Map<string, number>;

	constructor(baseApi: BaseApi) {
		this.baseAPI = baseApi;
		this.reports = null;
		this.group = null;
		this.datasets = new Map<string, Dataset>();
		this.datasources = new Map<string, DataSources>();
		this.users = new Map<string, User>();
		this.workspaces = new Map<string, string>();
		this.reportsPerDataset = new Map<string, number>();
		this.reportsPerUser = new Map<string, number>();
		this.reportsPerDay = hydrateMapForLastNMonths(12);
	}

	async getReports(workspaceId: string): Promise<PowerBIReports | null> {
		var url = "https://api.powerbi.com/v1.0/myorg/admin/reports";
		const currentTimestamp = Math.floor(Date.now() / 1000);

		// set a 10min lock.
		var lockTimeout = sessionStorage.getItem("reports_as_admin_lock");
		var lockTimeoutAsNumber: number = Number(lockTimeout);

		if (lockTimeoutAsNumber > currentTimestamp) {
			console.log("report_as_admin_lock is locked");

			var savedReports = sessionStorage.getItem("reports_as_admin");
			if (savedReports && savedReports !== "") {
				this.reports = JSON.parse(savedReports);
				if (this.reports) {
					if (workspaceId !== "all" && workspaceId !== "") {
						console.log("filtering reports", workspaceId);
						var reports = this.reports.value.filter(
							(report) => report.workspaceId === workspaceId
						);
						this.reports.value = reports;
					}

					this.countReportsForUsers(this.reports);
					this.countReportsInDays(this.reports);
				}

				return this.reports;
			}
		}

		console.log("report_as_admin_lock is unlocked");
		console.log("report_as_admin_lock is locking for 10mins");

		sessionStorage.setItem(
			"reports_as_admin_lock",
			(currentTimestamp + 10 * 60).toString()
		);

		console.log("calling get reports");
		var response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error("failed to get reports as admin");
		}

		const data: PowerBIReports = await response.json();

		this.reports = data;
		sessionStorage.setItem("reports_as_admin", JSON.stringify(data));

		if (workspaceId !== "all" && workspaceId !== "") {
			console.log("filtering reports", workspaceId);
			this.reports.value = this.reports.value.filter(
				(report) => report.workspaceId === workspaceId
			);
		}

		this.countReportsForUsers(this.reports);
		this.countReportsInDays(this.reports);

		return this.reports;
	}

	async getGroupDatasets(workspaceId: string): Promise<Group | null> {
		var url =
			"https://api.powerbi.com/v1.0/myorg/admin/groups?$expand=datasets,reports,users&$top=100";
		const currentTimestamp = Math.floor(Date.now() / 1000);
		const cacheKey = "group_as_admin";
		const lockKey = "group_as_admin_lock";

		// set a 10min lock.
		var lockTimeout = sessionStorage.getItem(lockKey);
		var lockTimeoutAsNumber: number = Number(lockTimeout);

		if (lockTimeoutAsNumber > currentTimestamp) {
			console.log(lockKey, "is locked");

			var savedReports = sessionStorage.getItem(cacheKey);
			if (savedReports && savedReports !== "") {
				this.group = JSON.parse(savedReports);
				if (!this.group) {
					return this.group;
				}

				this.group.value.forEach((v) => {
					this.workspaces.set(v.id, v.name);
				});

				if (workspaceId !== "all" && workspaceId !== "") {
					console.log("filtering groups", workspaceId);
					var reports = this.group.value.filter((w) => w.id === workspaceId);
					this.group.value = reports;
				}

				this.sortDatasets(this.group);
				this.countReportsForDatasets(this.group);
				this.sortUsers(this.group);

				return this.group;
			}
		}

		console.log(lockKey, "is unlocked");
		console.log(lockKey, "is locking for 10mins");

		sessionStorage.setItem(lockKey, (currentTimestamp + 10 * 60).toString());

		console.log("calling get group datasets");
		var response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error("failed to get reports as admin");
		}

		const data: Group = await response.json();

		this.group = data;
		this.group.value = this.group.value.filter(
			(workspace) => workspace.type !== "PersonalGroup"
		);
		this.group.value = this.group.value.filter(
			(workspace) => workspace.state !== "Deleted"
		);

		this.group.value.forEach((v) => {
			this.workspaces.set(v.id, v.name);
		});

		sessionStorage.setItem(cacheKey, JSON.stringify(data));

		if (workspaceId !== "all" && workspaceId !== "") {
			console.log("filtering groups", workspaceId);
			this.group.value = this.group.value.filter((w) => w.id === workspaceId);
		}

		this.sortDatasets(this.group);
		this.countReportsForDatasets(this.group);
		this.sortUsers(this.group);

		return this.group;
	}

	async getDatasources(id: string): Promise<DataSources> {
		var url =
			"https://api.powerbi.com/v1.0/myorg/admin/datasets/" +
			id +
			"/datasources";
		const cacheKey = url;

		var savedReports = sessionStorage.getItem(cacheKey);
		if (savedReports) {
			return JSON.parse(savedReports);
		}

		console.log("calling get datasources for ", id);
		var response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error("failed to get datasources");
		}

		const data: DataSources = await response.json();

		sessionStorage.setItem(cacheKey, JSON.stringify(data));

		return data;
	}

	async giveSelfPermissionsToGroup(
		groupId: string,
		userEmail: string,
		userId: string
	): Promise<null> {
		const url = `https://api.powerbi.com/v1.0/myorg/admin/groups/${groupId}/users`;
		const body = {
			emailAddress: userEmail,
			groupUserAccessRight: "Admin",
		};

		var response = await this.baseAPI.post(url, body);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error("failed to download report");
		}

		return null;
	}

	async downloadReport(reportId: string): Promise<string> {
		const url = `https://api.powerbi.com/v1.0/myorg/reports/${reportId.toLowerCase()}/Export`;

		var response = await this.baseAPI.get(url);
		if (!response) {
			throw new Error("request failed");
		}
		if (!response.ok) {
			throw new Error("failed to download report");
		}

		// Get the blob data from the response
		const blob = await response.blob();

		const base64Content = await blobToBase64(blob);

		return base64Content;
	}

	getWorkspaces(): Map<string, string> {
		return this.workspaces;
	}
	getDataset(id: string): Dataset | undefined {
		return this.datasets.get(id);
	}
	getDatasets(): Map<string, Dataset> {
		return this.datasets;
	}
	getDatasetCounts(): Map<string, number> {
		return this.reportsPerDataset;
	}
	getUsers(): Map<string, User> {
		return this.users;
	}
	getReportsPerUser(): Map<string, number> {
		return this.reportsPerUser;
	}
	getReportsPerDate(): Map<string, number> {
		return this.reportsPerDay;
	}

	private sortUsers(g: Group) {
		g.value.forEach((w) => {
			w.users.forEach((d) => {
				this.users.set(d.identifier, d);
			});
		});
	}

	private sortDatasets(g: Group) {
		g.value.forEach((w) => {
			w.datasets.forEach((d) => {
				this.datasets.set(d.id, d);
			});
		});
	}

	private countReportsForDatasets(g: Group) {
		let counts = new Map<string, number>();

		g.value.forEach((w) => {
			w.reports.forEach((r) => {
				var count = counts.get(r.datasetId);
				if (count) {
					count++;
				} else {
					count = 1;
				}

				counts.set(r.datasetId, count);
			});
		});

		this.reportsPerDataset = counts;
	}

	private countReportsForUsers(g: PowerBIReports) {
		this.reportsPerUser = new Map();
		g.value.forEach((r) => {
			var count = this.reportsPerUser.get(r.createdBy);
			if (count) {
				count++;
			} else {
				count = 1;
			}

			this.reportsPerUser.set(r.createdBy, count);
		});
	}

	private countReportsInDays(r: PowerBIReports) {
		this.reportsPerDay = hydrateMapForLastNMonths(1);

		const currentDate = new Date();
		currentDate.setDate(currentDate.getDate() - 30);

		r.value.forEach((r) => {
			const date = new Date(r.createdDateTime);

			if (date < currentDate) {
				return;
			}

			const year = date.getFullYear().toString(); // "2021"
			const month = (date.getMonth() + 1).toString().padStart(2, "0"); // "10" (January is 0-based)
			const key = `${year}-${month}`;

			var count = this.reportsPerDay.get(key);
			if (count) {
				count++;
			} else {
				count = 1;
			}

			this.reportsPerDay.set(key, count);
		});
	}
}

export default PowerBIAPI;
