const clientId =
  process.env.REACT_APP_CLIENT_ID || "8d1f07b9-34e5-4017-bb5c-0687c081c3f5";
const tenantId = process.env.REACT_APP_AAD_APP_TENANT_ID;
const config = {
  initiateLoginEndpoint: process.env.REACT_APP_START_LOGIN_PAGE_URL,
  clientId,
  apiEndpoint:
    process.env.REACT_APP_FUNC_ENDPOINT ||
    "https://coe-licencing-server.azurewebsites.net/api",
  tabDomain: process.env.REACT_APP_TAB_DOMAIN,
  tenantId,
  authority: `https://login.microsoftonline.com/${tenantId || "common"}`,
  apiFunctionSp: process.env.REACT_APP_API_FUNCTION_SP,
};

export default config;
