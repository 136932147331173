import { Skeleton as MUISkeleton, Stack } from "@mui/material";
import _ from "lodash";

export const Skeleton = ({ rows }: { rows: number }) => {
	return (
		<Stack spacing={1}>
			{_.times(rows, (i) => (
				<MUISkeleton variant="rounded" height={30} key={i} />
			))}
		</Stack>
	);
};
