import { useEffect, useState } from "react";
import {
	Area,
	AreaChart,
	CartesianGrid,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from "recharts";
import {
	hydrateMapForLastNMonths,
	sortDateKeyedArray,
} from "../../helpers/dates";
import { Dataset, PowerBIReports } from "../../helpers/powerbi-api";
import Card from "../Card";
import DateFilter from "../DateFilter";
import ChartBar from "../Icons/ChartBar";

interface Props {
	reports: PowerBIReports;
	datasets: Map<string, Dataset>;
}

export default function DatasetsPerReportOverTime(props: Props) {
	const [data, setData] = useState<{ name: string; count: number }[]>([]);
	// default is 90 days (3 months)
	const [filterTime, setFilterTime] = useState(90);

	useEffect(() => {
		const buildData = () => {
			const reports: Map<string, number> = hydrateMapForLastNMonths(
				filterTime / 30
			);
			const datasets: Map<string, number> = hydrateMapForLastNMonths(
				filterTime / 30
			);
			const graphData: Map<string, number> = hydrateMapForLastNMonths(
				filterTime / 30
			);

			const seen: Map<string, boolean> = new Map();

			const currentDate = new Date();
			currentDate.setDate(currentDate.getDate() - filterTime);

			props.datasets.forEach((d) => {
				if (seen.has(d.id)) {
					return;
				}

				const date = new Date(d.createdDate);
				if (date < currentDate) {
					return;
				}

				const year = date.getFullYear().toString(); // "2021"
				const month = (date.getMonth() + 1).toString().padStart(2, "0"); // "10" (January is 0-based)

				const key = `${year}-${month}`;

				var count = datasets.get(key);
				if (count) {
					count++;
				} else {
					count = 1;
				}
				datasets.set(key, count);
				seen.set(d.id, true);
			});

			props.reports.value.forEach((report) => {
				const date = new Date(report.createdDateTime);
				if (date < currentDate) {
					return;
				}

				const year = date.getFullYear().toString();
				const month = (date.getMonth() + 1).toString().padStart(2, "0");

				const key = `${year}-${month}`;

				var count = reports.get(key);
				if (count) {
					count++;
				} else {
					count = 1;
				}
				reports.set(key, count);
			});

			// total all the reports datasets for a current month. then work out the average of
			// datasets used by reports.
			// the calc should be datasetTotal/reportTotal

			datasets.forEach((count, date) => {
				let reportCount = reports.get(date);
				if (!reportCount) {
					graphData.set(date, 0);
					return;
				}

				graphData.set(date, count / reportCount);
			});

			let d = Array.from(graphData, ([name, count]) => ({ name, count }));
			d = sortDateKeyedArray(d);

			setData(d);
		};

		buildData();
	}, [props.reports, props.datasets, filterTime]);

	return (
		<Card
			title="Datasets Per Report Over Time"
			subtitle="If the numbers here drop, more reports are using centralised datasets"
			icon={<ChartBar />}
			headerChildren={
				<div className="w-40">
					<DateFilter onChange={(v: number) => setFilterTime(v)} />
				</div>
			}
		>
			<div className="h-[300px] text-sm">
				<ResponsiveContainer width="100%" height="100%">
					<AreaChart
						data={data}
						margin={{
							top: 10,
							right: 10,
							left: -5,
							bottom: 10,
						}}
					>
						<defs>
							<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor="#de430a" stopOpacity={0.5} />
								<stop offset="80%" stopColor="#de430a" stopOpacity={0} />
							</linearGradient>
						</defs>
						<CartesianGrid
							strokeDasharray="0 0"
							vertical={false}
							stroke="#32333e"
						/>
						<YAxis
							width={30}
							stroke="#a1a1aa"
							axisLine={false}
							tickLine={false}
							className="text-zinc-400"
						/>
						<XAxis dataKey="name" stroke="#a1a1aa" />
						<Area
							type="monotone"
							dataKey="count"
							stroke="#de430a"
							strokeWidth={2}
							fillOpacity={1}
							fill="url(#colorUv)"
						/>
					</AreaChart>
				</ResponsiveContainer>
			</div>
		</Card>
	);
}
