import { useEffect, useState } from "react";
import {
	Area,
	AreaChart,
	CartesianGrid,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from "recharts";
import {
	hydrateMapForLastNMonths,
	sortDateKeyedArray,
} from "../../helpers/dates";
import { Dataset } from "../../helpers/powerbi-api";
import Card from "../Card";
import DateFilter from "../DateFilter";
import ChartBar from "../Icons/ChartBar";

interface Props {
	datasets: Map<string, Dataset>;
}

export default function DatasetsOverTime(props: Props) {
	const [data, setData] = useState<{ name: string; count: number }[]>([]);
	// default is 90 days (3 months)
	const [filterTime, setFilterTime] = useState(90);

	useEffect(() => {
		const buildGraphData = () => {
			const m = hydrateMapForLastNMonths(filterTime / 30);

			const currentDate = new Date();
			currentDate.setDate(currentDate.getDate() - filterTime);

			props.datasets.forEach((d) => {
				const date = new Date(d.createdDate);
				if (date < currentDate) {
					return;
				}

				const year = date.getFullYear().toString(); // "2021"
				const month = (date.getMonth() + 1).toString().padStart(2, "0"); // "10" (January is 0-based)

				const key = `${year}-${month}`;

				var count = m.get(key);
				if (count) {
					count++;
				} else {
					count = 1;
				}

				m.set(key, count);
			});

			let d = Array.from(m, ([name, count]) => ({ name, count }));
			d = sortDateKeyedArray(d);

			setData(d);
		};
		buildGraphData();
	}, [filterTime, props.datasets]);
	return (
		<Card
			title="Datasets Over Time"
			subtitle="How often are new datasets being created?"
			icon={<ChartBar />}
			headerChildren={
				<div className="w-40">
					<DateFilter onChange={(v: number) => setFilterTime(v)} />
				</div>
			}
		>
			<div className="h-[300px] text-sm">
				<ResponsiveContainer width="100%" height="100%">
					<AreaChart
						data={data}
						margin={{
							top: 10,
							right: 10,
							left: -5,
							bottom: 10,
						}}
					>
						<defs>
							<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
								<stop offset="5%" stopColor="#de430a" stopOpacity={0.5} />
								<stop offset="80%" stopColor="#de430a" stopOpacity={0} />
							</linearGradient>
						</defs>
						<CartesianGrid
							strokeDasharray="0 0"
							vertical={false}
							stroke="#32333e"
						/>
						<YAxis
							width={30}
							stroke="#a1a1aa"
							axisLine={false}
							tickLine={false}
							className="text-zinc-400"
						/>
						<XAxis dataKey="name" stroke="#a1a1aa" />
						<Area
							type="monotone"
							dataKey="count"
							stroke="#de430a"
							strokeWidth={2}
							fillOpacity={1}
							fill="url(#colorUv)"
						/>
					</AreaChart>
				</ResponsiveContainer>
			</div>
		</Card>
	);
}
