import { useCallback, useEffect, useState } from "react";
import { Subscription } from "../helpers/azure-api";
import { Cluster, DatabricksWorkspace } from "../helpers/databricks-api";
import useApi from "../hooks/useApi";
import Card from "./Card";
import Beaker from "./Icons/Beaker";
import { Skeleton } from "./Skeleton";

const WorkspaceRow = ({ workspace }: { workspace: DatabricksWorkspace }) => {
	return (
		<div
			className="text-gray-200 grid grid-cols-12 
              gap-2 py-2 text-sm border-b border-gray-700
              hover:bg-zinc-700 items-center"
		>
			<div className="col-span-2">{workspace.properties.workspaceId}</div>
			<div className="col-span-2" id={workspace.id}>
				{workspace.name}
			</div>
			<div className="col-span-2">
				{
					workspace.properties.managedResourceGroupId.match(
						/resourceGroups\/(.*)/
					)?.[1]
				}
			</div>
			<div className="col-span-2">{workspace.location}</div>
			<div className="col-span-2">{workspace.sku.name}</div>
			<div className="col-span-4 flex justify-end"></div>
		</div>
	);
};

const ClusterRow = ({ cluster }: { cluster: Cluster }) => {
	return (
		<div
			className="text-gray-200 grid grid-cols-12 
          gap-2 py-2 text-sm border-b border-gray-700
          hover:bg-zinc-700 items-center"
		>
			<div className="col-span-2">{cluster.cluster_id}</div>
			<div className="col-span-2">{cluster.cluster_name}</div>
			<div className="col-span-2">{cluster.node_type_id}</div>
			<div className="col-span-2">{cluster.state}</div>
			<div className="col-span-2">
				{cluster.autoscale
					? `${cluster.autoscale?.min_workers}-${cluster.autoscale?.max_workers}`
					: cluster.num_workers}
			</div>
		</div>
	);
};

export default function Databricks() {
	const { azureApi, databricksApi } = useApi();

	const [loadingWorkspaces, setLoadingWorkspaces] = useState(true);
	const [clusters, setClusters] = useState<Cluster[]>([]);
	const [loadingClusters, setLoadingClusters] = useState(true);
	const [warehouses, setWarehouses] = useState<any[]>([]);
	const [loadingWarehouses, setLoadingWarehouses] = useState(true);
	const [workspaces, setWorkspaces] = useState<DatabricksWorkspace[]>([]);

	const getSubscriptions = useCallback(async () => {
		const subs = await azureApi.getAzureSubscriptions();
		return subs.value;
	}, [azureApi]);

	const getWorkspaces = useCallback(
		async (subscriptions: Subscription[]) => {
			console.log("getting workspace data");
			const foundWorkspaces: Record<string, DatabricksWorkspace> = {};

			for (const sub of subscriptions) {
				console.log("getting workspaces for sub", sub.id);

				const subWorkspaces = (await azureApi.getDatabricksWorkspaces(sub.id))
					.value;
				for (const ws of subWorkspaces) {
					foundWorkspaces[ws.id] = ws;
				}
			}

			const workspaces = Object.values(foundWorkspaces);
			console.log("done getting workspaces");
			return workspaces;
		},
		[azureApi]
	);

	const getClusters = useCallback(
		async (workspaces: DatabricksWorkspace[]) => {
			const clustersFound: Record<string, Cluster> = {};
			for (const workspace of workspaces) {
				await databricksApi
					.getClusters(workspace.properties.workspaceUrl)
					.then((res) => {
						res.clusters?.forEach((cluster) => {
							clustersFound[cluster.cluster_id] = cluster;
						});
					});
			}
			return Object.values(clustersFound);
		},
		[databricksApi]
	);

	const getWarehouses = useCallback(
		async (workspaces: DatabricksWorkspace[]) => {
			const warehousesFound: Record<string, any> = {};
			for (const workspace of workspaces) {
				await databricksApi
					.getWarehouses(workspace.properties.workspaceUrl)
					.then((res) => {
						res.warehouses?.forEach((warehouse: any) => {
							warehousesFound[warehouse.warehouse_id] = warehouse;
						});
					});
			}
			return Object.values(warehousesFound);
		},
		[databricksApi]
	);

	const fetchData = useCallback(async () => {
		const subscriptions = await getSubscriptions();
		const workspaces = await getWorkspaces(subscriptions);
		setWorkspaces(workspaces);
		setLoadingWorkspaces(false);

		const clusters = await getClusters(workspaces);
		setClusters(clusters);
		setLoadingClusters(false);

		const warehouses = await getWarehouses(workspaces);
		setWarehouses(warehouses);
		setLoadingWarehouses(false);
	}, [getClusters, getSubscriptions, getWorkspaces]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<div className="col-span-12">
			<div>
				<Card
					title="Databricks Workspaces"
					icon={<Beaker className="w-6" />}
					className="mb-8"
				>
					<div className="grid grid-cols-12 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full sticky bg-secondary text-sm">
						<div className="col-span-2">ID</div>
						<div className="col-span-2">Name</div>
						<div className="col-span-2">Resource Group Name</div>
						<div className="col-span-2">Location</div>
						<div className="col-span-2">SKU</div>
					</div>

					{loadingWorkspaces ? (
						<Skeleton rows={2} />
					) : (
						Array.from(workspaces).map((ws) => (
							<WorkspaceRow workspace={ws} key={ws.id} />
						))
					)}
				</Card>
				<Card
					title="Databricks Clusters"
					icon={<Beaker className="w-6" />}
					className="mb-8"
				>
					<div className="grid grid-cols-12 gap-2 text-zinc-400 mb-2 py-2 top-0 w-full sticky bg-secondary text-sm">
						<div className="col-span-2">ID</div>
						<div className="col-span-2">Name</div>
						<div className="col-span-2">SKU</div>
						<div className="col-span-2">Status</div>
						<div className="col-span-2">Number of Workers</div>
					</div>

					{loadingClusters ? (
						<Skeleton rows={2} />
					) : (
						Array.from(clusters).map((cluster) => (
							<ClusterRow cluster={cluster} key={cluster.cluster_id} />
						))
					)}
				</Card>
			</div>
		</div>
	);
}
