import { useEffect, useState } from "react";

export default function Loading() {
    const [currentDiv, setCurrentDiv] = useState(1);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentDiv((prevDiv) => (prevDiv === 3 ? 1 : prevDiv + 1));
      }, 200);
  
      return () => {
        clearInterval(interval);
      };
    }, []);

    return (
        <div className="text-7xl text-center ">
            <div className="mb-6 font-medium text-3xl">Loading data...</div>
            {currentDiv === 1 && <div>🚀</div>}
            {currentDiv === 2 && <div>🔥</div>}
            {currentDiv === 3 && <div>🪄</div>}
        </div>
    )
}