import { useEffect, useState } from "react";
import PowerBIAPI, {
	DataSources,
	Dataset,
	User,
} from "../../helpers/powerbi-api";
import Card from "../Card";
import Dropdown from "../Dropdown";
import DropdownItem from "../DropdownItem";
import ArrowTopRightSquare from "../Icons/ArrowTopRightSquare";
import CheveronDown from "../Icons/ChevronDown";
import ChevronUp from "../Icons/ChevronUp";
import DocumentText from "../Icons/DocumentText";
import Modal from "../Modal";

interface Props {
	datasetCounts: Map<string, number>;
	datasets: Map<string, Dataset>;
	users: Map<string, User>;
	apiManager: PowerBIAPI;
}

export default function DatasetBreakdown(props: Props) {
	const [data, setData] = useState<[string, number][]>([]);

	useEffect(() => {
		setData(Array.from(props.datasetCounts));
	}, [props.datasetCounts]);

	const getDatasetName = (id: string): string => {
		var dataset = props.datasets.get(id);
		if (dataset) {
			return dataset.name;
		}

		return "";
	};

	const getDatasetCreatedBy = (id: string): string => {
		var dataset = props.datasets.get(id);
		if (dataset) {
			return getUserDisplayName(dataset.configuredBy);
		}

		return "";
	};

	const getUserDisplayName = (id: string): string => {
		var user = props.users.get(id);
		if (user) {
			return user.displayName;
		}
		return id;
	};

	const [modalState, setModalState] = useState(false);
	const [datasetModal, setModalDataset] = useState("");
	const [datasources, setDatasources] = useState<DataSources>({ value: [] });
	const closeModal = () => {
		setModalState(false);
	};

	const displayDatasources = (dataset: string) => {
		props.apiManager.getDatasources(dataset).then((ds) => {
			setDatasources(ds);
			setModalState(true);
			setModalDataset(getDatasetName(dataset));
		});
	};

	const [dataModalState, dataSetModalState] = useState(false);
	const [dataset, setDataset] = useState<Dataset | null>(null);
	const closeDataModal = () => {
		dataSetModalState(false);
	};

	const displayAllDatasetData = (id: string) => {
		var dataset = props.datasets.get(id);
		if (dataset) {
			setDataset(dataset);
			dataSetModalState(true);
		}
	};

	const [sortingCol, setSortingCol] = useState({ direction: "desc", col: "" });
	const sortIntCol = (column: string) => {
		let sorted = data.sort((a: [string, number], b: [string, number]) => {
			if (sortingCol.direction === "asc") {
				setSortingCol({ direction: "desc", col: column });
				return b[1] - a[1];
			}

			setSortingCol({ direction: "asc", col: column });

			return a[1] - b[1];
		});

		setData(sorted);
	};

	let chevronClasses = "w-4 text-gray-500";

	return (
		<Card
			title="Dataset Breakdown"
			subtitle="How are our key datasets being used?"
			icon={<DocumentText className="w-6 mr-3" />}
		>
			<Modal
				title={`Datasources for ${datasetModal}`}
				open={modalState}
				onClose={closeModal}
			>
				{datasources.value.length > 0 ? (
					<div>
						<div className="grid grid-cols-12 gap-2 text-gray-500 mb-2 py-2 absolute top-0 w-full sticky text-sm flex items-center">
							<div className="col-span-6 px-2">Datasource</div>
							<div className="col-span-6 px-2">Type</div>
						</div>

						{datasources.value.map((v, key) => (
							<div
								key={key}
								className="text-gray-200 grid grid-cols-12 
                                gap-2 py-2 text-sm border-b border-zinc-800/40
                                flex items-center"
							>
								<div className="col-span-6 px-2">
									{v.name ? v.name : v.datasourceId}
								</div>
								<div className="col-span-6 px-2">{v.datasourceType}</div>
							</div>
						))}
					</div>
				) : (
					<div className="text-center text-xl font-medium">
						Found no data sources for this dataset.
					</div>
				)}
			</Modal>

			<Modal open={dataModalState} onClose={closeDataModal}>
				{dataset ? (
					<div>
						<h2 className="text-xl font-medium mb-6 pb-2 border-b border-gray-600">
							Dataset raw info
						</h2>
						<div className="grid grid-cols-12 gap-3">
							<div className="col-span-3">id</div>
							<div className="col-span-9"> {dataset.id}</div>
							<div className="col-span-3">name</div>
							<div className="col-span-9"> {dataset.name}</div>
							<div className="col-span-3">configured by</div>
							<div className="col-span-9"> {dataset.configuredBy}</div>
							<div className="col-span-3">created date</div>
							<div className="col-span-9">{dataset.createdDate}</div>
							<div className="col-span-3">target storage mode</div>
							<div className="col-span-9"> {dataset.targetStorageMode}</div>
						</div>
					</div>
				) : (
					"loading"
				)}
			</Modal>

			<div className="max-h-[300px] overflow-y-scroll relative custom-scrollbar">
				<div className="grid grid-cols-12 gap-2 text-zinc-400 mb-2 py-2 absolute top-0 w-full sticky bg-secondary text-sm">
					<div className="col-span-6 px-2">Dataset</div>
					<div className="col-span-2 px-2">Created by</div>
					<div
						className="col-span-2 px-2 flex items-center cursor-pointer"
						onClick={() => sortIntCol("count")}
					>
						<div className="mr-1"># Reports</div>
						{sortingCol.col === "count" ? (
							sortingCol.direction === "asc" ? (
								<CheveronDown className={chevronClasses} />
							) : (
								<ChevronUp className={chevronClasses} />
							)
						) : (
							<CheveronDown className={chevronClasses} />
						)}
					</div>
					<div className="col-span-2 px-2"></div>
				</div>

				<div className="">
					{data.map(([key, value]) => (
						<div
							key={key}
							className="text-gray-200 grid grid-cols-12 
                            gap-2 py-2 text-sm border-b border-zinc-800/40
                            hover:bg-zinc-700 flex items-center"
						>
							<div className="col-span-6 px-2 flex" id={key}>
								{getDatasetName(key)}
								<div onClick={() => displayAllDatasetData(key)}>
									<ArrowTopRightSquare className="ml-2 w-3 text-gray-400/30 hover:text-gray-400" />
								</div>
							</div>
							<div className="col-span-2 px-2">{getDatasetCreatedBy(key)}</div>
							<div className="col-span-2 px-2">{value}</div>
							<div className="col-span-2 px-2 flex justify-end">
								<Dropdown className="" dropdownClassName="w-40 text-base">
									<DropdownItem>
										<div className="" onClick={() => displayDatasources(key)}>
											View Datasources
										</div>
									</DropdownItem>
								</Dropdown>
							</div>
						</div>
					))}
				</div>
			</div>
		</Card>
	);
}
