import { TeamsContextWithCredential } from "@microsoft/teamsfx-react";
import { createContext, useContext } from "react";

export const TeamsContext = createContext<
	TeamsContextWithCredential | undefined
>(undefined);

export const useTeamsContext = () => {
	const ctx = useContext(TeamsContext);
	if (!ctx) {
		throw new Error("useTeamsContext was used outside the provider");
	}

	return ctx;
};
