import { useEffect, useState } from "react";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
import { sortDateKeyedArray } from "../../helpers/dates";
import { PowerBIReport, PowerBIReports, User } from "../../helpers/powerbi-api";
import Card from "../Card";
import Dropdown from "../Dropdown";
import DropdownItem from "../DropdownItem";
import CheveronDown from "../Icons/ChevronDown";
import ChevronUp from "../Icons/ChevronUp";
import DocumentText from "../Icons/DocumentText";
import Users from "../Icons/Users";
import Modal from "../Modal";

interface Props {
	reportsPerUser: Map<string, number>;
	users: Map<string, User>;
	reports: PowerBIReports;
}

export default function Top5Creators(props: Props) {
	const [data, setData] = useState<[string, number][]>([]);

	useEffect(() => {
		setData(Array.from(props.reportsPerUser));
	}, [props.reportsPerUser]);

	const getUserDisplayName = (id: string): string => {
		var user = props.users.get(id);
		if (user) {
			return user.displayName;
		}
		return id;
	};

	const [sortingCol, setSortingCol] = useState({
		direction: "desc",
		col: "",
	});

	const sortIntCol = (column: string) => {
		let sorted = data.sort((a: [string, number], b: [string, number]) => {
			if (sortingCol.direction === "asc") {
				setSortingCol({ direction: "desc", col: column });
				return b[1] - a[1];
			}

			setSortingCol({ direction: "asc", col: column });

			return a[1] - b[1];
		});

		setData(sorted);
	};

	const [reportsModalState, setReportModalState] = useState(false);
	const [reports, setReports] = useState<PowerBIReport[] | null>(null);
	const closeReportsModal = () => {
		setReportModalState(false);
	};

	const displayReports = (userId: string) => {
		let fr: PowerBIReport[] = [];
		props.reports.value.forEach((v, i) => {
			if (v.createdBy === userId) {
				fr.push(v);
			}
		});

		setReportModalState(true);
		setReports(fr);
	};

	let chevronClasses = "w-4 text-gray-500";

	const datasetSparkLineData = (userId: string) => {
		var data: Map<string, number> = hydrateMapForLastNMonths(4);

		props.reports.value.forEach((v, i) => {
			if (v.createdBy === userId) {
				const date = new Date(v.createdDateTime);
				const key = date.getFullYear() + "" + date.getMonth();

				var value = data.get(key);
				if (value) {
					data.set(key, value + 1);
				} else {
					data.set(key, 1);
				}
			}
		});

		let d = Array.from(data, ([name, count]) => ({ name, count }));
		d = sortDateKeyedArray(d);

		return d;
	};

	function hydrateMapForLastNMonths(months: number) {
		const map = new Map();
		const currentDate = new Date();

		for (let i = 0; i < months; i++) {
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1; // Month is 0-indexed
			const key = `${year}${month.toString().padStart(2, "0")}`;

			map.set(key, 0); // Initialize count to 0
			currentDate.setMonth(currentDate.getMonth() - 1); // Move to the previous month
		}

		return map;
	}

	return (
		<Card
			title="Top Reporters"
			subtitle="Who are our key developers, and what are they making?"
			icon={<Users />}
		>
			<div className="max-h-[300px] overflow-y-scroll relative">
				<div className="grid grid-cols-12 gap-2 text-zinc-400 mb-2 py-2 absolute top-0 w-full sticky bg-secondary text-sm">
					<div className="col-span-5 px-2">Reporter</div>
					<div
						className="col-span-2 px-2 flex items-center cursor-pointer"
						onClick={() => sortIntCol("count")}
					>
						<div className="mr-2"># Created</div>
						{sortingCol.col === "count" ? (
							sortingCol.direction === "asc" ? (
								<CheveronDown className={chevronClasses} />
							) : (
								<ChevronUp className={chevronClasses} />
							)
						) : (
							<CheveronDown className={chevronClasses} />
						)}
					</div>
					<div className="col-span-2 px-2"></div>
					<div className="col-span-2 px-2"></div>
				</div>

				<div className="">
					{Array.from(data).map(([key, value]) => {
						return (
							<div
								key={key}
								className="text-gray-200 grid grid-cols-12 
                        gap-2 py-2 text-sm border-b border-zinc-800/40
                        hover:bg-zinc-700 flex items-center"
							>
								<div className="col-span-5 px-2">{getUserDisplayName(key)}</div>
								<div className="col-span-2 px-2">{value}</div>
								<div className="col-span-3 px-2 h-8">
									<ResponsiveContainer width="100%" height="100%">
										<AreaChart data={datasetSparkLineData(key)}>
											<defs>
												<linearGradient
													id="colorUv"
													x1="0"
													y1="0"
													x2="0"
													y2="1"
												>
													<stop
														offset="5%"
														stopColor="#de430a"
														stopOpacity={0.5}
													/>
													<stop
														offset="80%"
														stopColor="#de430a"
														stopOpacity={0}
													/>
												</linearGradient>
											</defs>
											<Area
												type="monotone"
												dataKey="count"
												stroke="#de430a"
												strokeWidth={2}
												fillOpacity={1}
												fill="url(#colorUv)"
											/>
										</AreaChart>
									</ResponsiveContainer>
								</div>
								<div className="col-span-2 px-2 flex justify-end">
									<Dropdown className="" dropdownClassName="w-40 text-base">
										<DropdownItem onClick={() => displayReports(key)}>
											<div>View Reports</div>
										</DropdownItem>
									</Dropdown>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<Modal open={reportsModalState} onClose={closeReportsModal}>
				<h2 className="text-xl font-medium mb-6 pb-2 border-b border-gray-600 flex">
					<DocumentText className="w-6 mr-3" />
					Reports
				</h2>
				{reports ? (
					<div>
						<div className="grid grid-cols-12 gap-2 text-gray-500 mb-2 py-2 absolute top-0 w-full sticky text-sm flex items-center">
							<div className="col-span-6 px-2">Name</div>
						</div>

						{reports.map((v, key) => (
							<div
								key={key}
								className="text-gray-200 grid grid-cols-12 
                                gap-2 py-2 text-sm border-b border-gray-700
                                flex items-center"
							>
								<div className="col-span-6 px-2">{v.name ? v.name : v.id}</div>
							</div>
						))}
					</div>
				) : (
					"loading..."
				)}
			</Modal>
		</Card>
	);
}
